
const editIcon = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_947_6541)">
<path d="M9.625 3.5H3.5C3.03587 3.5 2.59075 3.68437 2.26256 4.01256C1.93437 4.34075 1.75 4.78587 1.75 5.25V17.5C1.75 17.9641 1.93437 18.4092 2.26256 18.7374C2.59075 19.0656 3.03587 19.25 3.5 19.25H15.75C16.2141 19.25 16.6592 19.0656 16.9874 18.7374C17.3156 18.4092 17.5 17.9641 17.5 17.5V11.375" stroke="#BF8F02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.1875 2.18819C16.5356 1.84009 17.0077 1.64453 17.5 1.64453C17.9923 1.64453 18.4644 1.84009 18.8125 2.18819C19.1606 2.53628 19.3562 3.0084 19.3562 3.50069C19.3562 3.99297 19.1606 4.46509 18.8125 4.81319L10.5 13.1257L7 14.0007L7.875 10.5007L16.1875 2.18819Z" stroke="#BF8F02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_947_6541">
<rect width="21" height="21" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}

export default editIcon