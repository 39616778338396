import React, { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import { priceIntelligenceInstance } from "../config/axios";
import { calculateThreePercentLess } from "../utils/function";
import { PriceContext } from "../context";
import { useNavigate } from "react-router-dom";
import UAParser from 'ua-parser-js';
// import { Button } from 'react-bootstrap'

const Sidebar = ({ show, handleClose }) => {
  const getContext = useContext(PriceContext);
  const navigate = useNavigate();
  const {
    PreviousSearchesData,
    setPreviousSearchesData,
    setPricestepData,
    piCredits,
    setPICredits,
    setCheckAssets,
    loader,
  } = getContext;
  // console.log({PreviousSearchesData});
  const [usermails, setUserMails] = useState("");

  // const [data,setData]=useState(null)
  // const SearchItem = [
  //   {
  //     id:"1234567",
  //     amt:"$20,000 - $40,000"
  //   },
  //   {
  //     id:"5248647",
  //     amt:"$25,000 - $54,000"
  //   },
  //   {
  //     id:"254862",
  //     amt:"$4,000 - $18,000"
  //   },
  //   {
  //     id:"321478",
  //     amt:"$12,000 - $35,000"
  //   },
  // ]
  const GetPreviousSearchesData = async () => {
    try {
      const response = await priceIntelligenceInstance().get(
        `/price/getAllreport`
      );
      if (response.data.status === 200) {
        setPreviousSearchesData(response.data.resultdata);
      } else {
        setPreviousSearchesData([]);
      }
    } catch (e) {
      console.log("errrr==>", e);
    }
  };

  const GetPICreditsData = async () => {
    try {
      const response = await priceIntelligenceInstance().get(
        `/price/get-pi-credits`
      );

      // console.log("pi", response)
      if (response?.data?.status === 200) {
        setPICredits(response?.data?.pi_credits);
      } else {
        setPICredits(null);
      }
    } catch (e) {
      console.log("errrr==>", e);
    }
  };

  // useEffect(()=>{
  //  var x=true;
  //  if(x){
  //   GetPreviousSearchesData()
  //  }
  //  return ()=>{x=false}
  //  // eslint-disable-next-line
  // },[])

  function calculateAdjustedPrice(element, checkAssets) {
    var priceValue = element.priceFields.price;

    if (element.giasaturation === "Fancy Deep") {
      priceValue = priceValue * 0.94;
    }

    return calculateThreePercentLess(priceValue, checkAssets) + " per ct";
  }

  // Example usage:

  const handleNavigationRoute = async (element) => {
    // Fetch IP address and parse user agent details concurrently
    const [ipResponse, uaResult] = await Promise.all([
      fetch("https://api.ipify.org?format=json"),
      new Promise((resolve) => resolve(new UAParser().getResult())),
    ]);

    const { ip } = await ipResponse.json();

    // Prepare the payload for the API
    const payload = {
      IP: ip,
      "Operating System": uaResult.os.name,
      Browser: uaResult.browser.name,
      Device: uaResult.device.model || "Unknown",
    };

    await priceIntelligenceInstance().post(
      `/price/sendHistoryWebhook/${element?.jobNumber}`,
      payload
    );

    var priceValue = element.priceFields.price;

    if (element.giasaturation === "Fancy Deep") {
      priceValue = priceValue * 0.94;
    }

    const price = calculateThreePercentLess(
      priceValue,
      element?.checkAssets || false
    );

    const jobNumber = element?.jobNumber;
    setPricestepData({ ...element?.priceFields, price, jobNumber });
    setCheckAssets(element?.checkAssets || false);
    const exportData = {
      ...element?.priceFields,
      price,
      jobNumber,
    };

    if (price && jobNumber && element?.priceFields) {
      handleClose();
      navigate("/result", {
        state: {
          checkAsset: element?.checkAssets || false,
          pricetepData: exportData,
          reportType: element?.giaReportNumber ? "byGia" : "manual",
        },
      });
    } else {
      return;
    }
  };

  // const handleLogout=async()=>{
  //   try {
  //     localStorage.removeItem("price#@user");
  //     localStorage.removeItem("price@refreshtoken");
  //     localStorage.removeItem("price#@useremail");
  //     navigate("/user-login")

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    const usermail = localStorage.getItem("price#@useremail");
    if (usermail) {
      setUserMails(usermail);
    }
  }, []);

  useEffect(() => {
    const usermail = localStorage.getItem("price#@useremail");
    if (usermail) {
      GetPICreditsData();
    }
    // eslint-disable-next-line
  }, [loader]);

  return (
    <Offcanvas
      className="pi-sidebar"
      show={show}
      onHide={handleClose}
      placement="end"
    >
      <Offcanvas.Header closeButton />
      <Offcanvas.Body>
        <Accordion className="sidebar-nav-list">
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => GetPreviousSearchesData()}>
              Previous searches
            </Accordion.Header>
            <Accordion.Body>
              {PreviousSearchesData &&
                PreviousSearchesData.length > 0 &&
                PreviousSearchesData.map((element, index) => {
                  return (
                    <span
                      onClick={() => handleNavigationRoute(element)}
                      role="presentation"
                      key={element?._id}
                    >
                      #PI{element?.jobNumber}{" "}
                      {element?.giaReportNumber &&
                        `(${element?.giaReportNumber})`}
                      <br />
                      {calculateAdjustedPrice(
                        element,
                        element?.checkAssets || false
                      )}
                    </span>
                  );
                })}
              {/* {SearchItem.map((item, i)=>(
                <span onClick={()=> console.log("search history")} role="presentation" key={i}>
                JOB# {item.id}
                <br />
                {item.amt} per ct
              </span>
              ))} */}
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="1">
            <Accordion.Header  onClick={()=>navigate("/profile")} id="accountSettingHeader">Account settings</Accordion.Header>

           
          </Accordion.Item> */}
          <Accordion.Item eventKey="2">
            <Accordion.Header id="accountSettingHeader">
              Credits : {piCredits}{" "}
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </Offcanvas.Body>

      <Offcanvas.Header>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <p className="w-100 d-flex gap-1 align-items-center mb-0">
            <span>
              <EmailIcon />
            </span>
            <span className="sliderEmail">{usermails}</span>
          </p>

          {/* <Button onClick={()=>{handleLogout()}} className="updateButton" >
     
        <span className='mt-0'>Logout</span></Button> */}
        </div>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default Sidebar;
const EmailIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.10645 4.24683H20.1064C21.2064 4.24683 22.1064 5.14683 22.1064 6.24683V18.2468C22.1064 19.3468 21.2064 20.2468 20.1064 20.2468H4.10645C3.00645 20.2468 2.10645 19.3468 2.10645 18.2468V6.24683C2.10645 5.14683 3.00645 4.24683 4.10645 4.24683Z"
        stroke="#6A77A2"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.1064 6.24683L12.1064 13.2468L2.10645 6.24683"
        stroke="#6A77A2"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
