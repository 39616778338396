export const PriceIntelligence = [
  {
    field: "weight",
    name: "weight",
    info: [
      "The FCPI matrix covers all weight categories from 1 carat up to 30 carats. Our data shows that the price of a 25-carat fancy color diamond could indicate prices for larger carat sizes, as prices do not deviate significantly above this weight.",
    ],
  },
  {
    field: "shape",
    name: "shape",
    info: [
      "The Betta version of the FCPI covers 7 shapes. Cushion, Radiant, Oval, Pear-Shape, Round, heart-shape and Emerald cut.",
    ],
  },
  {
    field: "color",
    name: "color",
    info: [
      "The Beta version of the FCPI covers prices for Yellow, Pink and Blue Fancy Color diamonds.",
    ],
  },
  {
    field: "saturation",
    name: "saturation",
    info: [
      "The Beta version of the FCPI reflects prices of Fancy Light, Fancy, Fancy Intense, Fancy Vivid and Fancy Deep.",
    ],
  },
 
  {
    field: "clarity",
    name: "clarity",
    info: [
      "The Beta version of the FCPI covers the following clarity groups: FL/IF, VVS, VS, SI1, and SI2. The I clarity was not included because it could represent extreme price variations. as fancy color diamonds are less sensitive to subtle changes in clarity, prices changes my appear less dramatic relative to colorless diamonds",
    ],
  },
  {
    field: "fluorescence",
    name: "fluorescence",
    info: [
      "The Beta version of the FCPI covers three types of fluorescent. Blue, Yellow and Orange. In cases where the fluorescence reinforces the color of a fancy color diamond, it will appear more attractive and receive a premium.",
    ],
  },
  {
    field: "Ratio",
    name: "ratio",
    info: [
      "The width-to-length ratio in a fancy color diamond could affect the overall aesthetics of the stone and, as a result, will impact its price.",
    ],
  },
  
  {
    field: "polish",
    name: "polish",
    info: [
      "Polish and symmetry play an insignificant role in fancy color diamonds as opposed to colorless diamonds. This is mainly because the impact that these two parameters have on the visual appearance of a diamond is small.",
    ],
  },
  {
    field: "symmetry",
    name: "symmetry",
    info: [
      "Polish and symmetry play an insignificant role in fancy color diamonds as opposed to colorless diamonds. This is mainly because the impact that these two parameters have on the visual appearance of a diamond is small.",
    ],
  },
  
  {
    field: "TRUE-FACE-UP",
    name: "trueFaceUp",
    info: [
      "The face-up size is an important factor in the pricing of a colored diamond. A very small face-up size will result in a discount, while a large face-up size will command a premium.",
    ],
  },
  {
    field: "color Inner-Grade",
    name: "colorInnerGrade",
    info: [
      "The Inner-grade refers to the richness of color within the GIA saturation grade. The grade 1 reflects the lowest possible strength of a specific GIA saturation, to the point of creating in doubt. The grade 2 reflects a low saturation within a specific GIA grade. The grade 3 reflects a strong saturation within A SPECIFIC GIA grade. The grade 4 reflects the highest level of saturation within a GIA grade. The grade 4+ reflects exceptional saturation within the Vivid grade of Yellow and Pink diamonds. The Inner-Grade plays an important roll in pricing a fancy color diamond.",
    ],
  },
  {
    field: "color Dispersion",
    name: "colorDispersion",
    info: [
      "Color dispersion refers to how well the color of a fancy color diamond is spread across the face-up view of the diamond, regardless of its saturation. Grade 1 reflects poor dispersion with a minimal amount of visible color, ranging from 0% to 40% on the face-up. Grade 2 reflects 41% to 60% visible color. Grade 3 reflects 61% to 85% visible color. Grade 4 reflects 86% to 95% visible color, and grade 5 reflects 96% to 99% visible color.",
    ],
  },
  {
    field: "color Undertone",
    name: "colorUndertone",
    info: [
      "Undertone refers to a subtle but visible color sensation that is not specified as a color modifier on the GIA report.",
      "If the undertone is noticeable, the diamond's color will not align with our expectations based on the color description in the report, resulting in a discount.",
      "Colors like blue and yellow have been deeply ingrained in our perception since our introduction to color. Pink, on the other hand, lacks a pure form, but many people associate it with a certain degree of purple, as seen in candies and children's toys.",
      "When a significant presence of brown, grey, or green is detected in a diamond's color, it deviates from the way most people envision these hues, resulting in a discount. The grading scale accounts for undertones as follows:",
      "Grade 1: Strong undertone bordering on a color modifier.",
      "#Grade 2: Noticeable undertone.",
      "Grade 3: Insignificant undertone (most common).",
      "Grade 4: No undertone, close to its pure form.",
      "In the case of pink diamonds, the premium or discount associated with the undertone, specifically when a purple modifier is present in the color description, is determined by the color temperature. This means that the extent to which the color leans towards a warm or cold undertone influences the pricing.",
    ],
  },
  {
    field: "single/pair",
    name: "singlePair",
    info: ["Matching pairs of diamonds are rare, and they command a premium"],
  },
  {
    field: "Summary",
    name: "summary",
    info: [""],
  },
];

export const PriceIntelligenceWithIdu = [
  {
    field: "weight",
    name: "weight",
    info: [
      "The FCPI matrix covers all weight categories from 1 carat up to 30 carats. Our data shows that the price of a 25-carat fancy color diamond could indicate prices for larger carat sizes, as prices do not deviate significantly above this weight.",
    ],
  },
  {
    field: "shape",
    name: "shape",
    info: [
      "The Betta version of the FCPI covers 7 shapes. Cushion, Radiant, Oval, Pear-Shape, Round, heart-shape and Emerald cut.",
    ],
  },
  {
    field: "color",
    name: "color",
    info: [
      "The Beta version of the FCPI covers prices for Yellow, Pink and Blue Fancy Color diamonds.",
    ],
  },
  {
    field: "saturation",
    name: "saturation",
    info: [
      "The Beta version of the FCPI reflects prices of Fancy Light, Fancy, Fancy Intense, Fancy Vivid and Fancy Deep.",
    ],
  },
 
  {
    field: "clarity",
    name: "clarity",
    info: [
      "The Beta version of the FCPI covers the following clarity groups: FL/IF, VVS, VS, SI1, and SI2. The I clarity was not included because it could represent extreme price variations. as fancy color diamonds are less sensitive to subtle changes in clarity, prices changes my appear less dramatic relative to colorless diamonds",
    ],
  },
  {
    field: "fluorescence",
    name: "fluorescence",
    info: [
      "The Beta version of the FCPI covers three types of fluorescent. Blue, Yellow and Orange. In cases where the fluorescence reinforces the color of a fancy color diamond, it will appear more attractive and receive a premium.",
    ],
  },
  {
    field: "Ratio",
    name: "ratio",
    info: [
      "The width-to-length ratio in a fancy color diamond could affect the overall aesthetics of the stone and, as a result, will impact its price.",
    ],
  },
  
  {
    field: "polish",
    name: "polish",
    info: [
      "Polish and symmetry play an insignificant role in fancy color diamonds as opposed to colorless diamonds. This is mainly because the impact that these two parameters have on the visual appearance of a diamond is small.",
    ],
  },
  {
    field: "symmetry",
    name: "symmetry",
    info: [
      "Polish and symmetry play an insignificant role in fancy color diamonds as opposed to colorless diamonds. This is mainly because the impact that these two parameters have on the visual appearance of a diamond is small.",
    ],
  },
  {
    field: "TRUE-FACE-UP",
    name: "trueFaceUp",
    info: [
      "The face-up size is an important factor in the pricing of a colored diamond. A very small face-up size will result in a discount, while a large face-up size will command a premium.",
    ],
  },
  {
    field: "IDU",
    name: "defaultIdu",
    info: [""],
  },
 
  
  {
    field: "single/pair",
    name: "singlePair",
    info: ["Matching pairs of diamonds are rare, and they command a premium"],
  },
  
  {
    field: "Summary",
    name: "summary",
    info: [""],
  },
];


export const DefaultIduData = [
  {
    field: "color Inner-Grade",
    name: "colorInnerGrade",
    info: [
      "The Inner-grade refers to the richness of color within the GIA saturation grade. The grade 1 reflects the lowest possible strength of a specific GIA saturation, to the point of creating in doubt. The grade 2 reflects a low saturation within a specific GIA grade. The grade 3 reflects a strong saturation within A SPECIFIC GIA grade. The grade 4 reflects the highest level of saturation within a GIA grade. The grade 4+ reflects exceptional saturation within the Vivid grade of Yellow and Pink diamonds. The Inner-Grade plays an important roll in pricing a fancy color diamond.",
    ],
  },
  {
    field: "color Dispersion",
    name: "colorDispersion",
    info: [
      "Color dispersion refers to how well the color of a fancy color diamond is spread across the face-up view of the diamond, regardless of its saturation. Grade 1 reflects poor dispersion with a minimal amount of visible color, ranging from 0% to 40% on the face-up. Grade 2 reflects 41% to 60% visible color. Grade 3 reflects 61% to 85% visible color. Grade 4 reflects 86% to 95% visible color, and grade 5 reflects 96% to 99% visible color.",
    ],
  },
  {
    field: "color Undertone",
    name: "colorUndertone",
    info: [
      "Undertone refers to a subtle but visible color sensation that is not specified as a color modifier on the GIA report.",
      "If the undertone is noticeable, the diamond's color will not align with our expectations based on the color description in the report, resulting in a discount.",
      "Colors like blue and yellow have been deeply ingrained in our perception since our introduction to color. Pink, on the other hand, lacks a pure form, but many people associate it with a certain degree of purple, as seen in candies and children's toys.",
      "When a significant presence of brown, grey, or green is detected in a diamond's color, it deviates from the way most people envision these hues, resulting in a discount. The grading scale accounts for undertones as follows:",
      "Grade 1: Strong undertone bordering on a color modifier.",
      "#Grade 2: Noticeable undertone.",
      "Grade 3: Insignificant undertone (most common).",
      "Grade 4: No undertone, close to its pure form.",
      "In the case of pink diamonds, the premium or discount associated with the undertone, specifically when a purple modifier is present in the color description, is determined by the color temperature. This means that the extent to which the color leans towards a warm or cold undertone influences the pricing.",
    ],
  },
]
