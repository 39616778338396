import React, { useState, useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { PriceContext } from "../../context";
const WeightForm = ({ name, weightValue, setWeightValue }) => {
  // console.log("name =>", name);

  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData , reportType } = getContext;
  const { carat_weight } = pricestepData || {};

  //console.log("===============>", pricestepData);

  useEffect(() => {
    setWeightValue(carat_weight);
    // eslint-disable-next-line
  }, [carat_weight]);

  // const [weightValue, setWeightValue] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const handleWeight = (key, value) => {
    // console.log("====>", { key, value });
    setWeightValue(value);
    if (value >= 1 && value <= 1.49) {
      setPricestepData({
        ...pricestepData,
        [key]: "1.01 - 1.49",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 1.5 && value <= 1.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "1.5 - 1.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 2 && value <= 2.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "2 - 2.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 3 && value <= 3.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "3 - 3.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 4 && value <= 4.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "4 - 4.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 5 && value <= 6.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "5 - 6.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 7 && value <= 7.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "7 - 7.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 8 && value <= 9.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "8 - 9.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 10 && value <= 10.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "10 - 10.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 11 && value <= 13.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "11 - 13.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 14 && value <= 16.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "14 - 16.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 17 && value <= 19.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "17 - 19.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 20 && value <= 24.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "20 - 24.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 25 && value <= 30) {
      setPricestepData({ ...pricestepData, [key]: "25>" });
      setErrorMsg(false);
    } else {
      setErrorMsg(true);
      setPricestepData({ ...pricestepData, [key]: "" });
    }
  };
  useEffect(() => {
    if (carat_weight) {
      handleWeight("weight", carat_weight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-wrapper">
      <Form.Group
        className={`form-input-wrapper ${errorMsg ? "error-msg" : ""}`}
      >
        <Form.Control
          type="text"
          pattern="[0-9]*/^\d*\.?\d*$/"
          inputMode="decimal"
          placeholder="ENTER CARAT WEIGHT"
          name={name}
          value={weightValue}
          className={reportType === "byGia" ? "disable_inp_bg" : ""}
          disabled={reportType === "byGia"?true:false}
          onChange={(e) => handleWeight(name, e.target.value)}
        />
        <Form.Label>ct</Form.Label>
        {errorMsg && (
          <span className="error-text">
            Please enter value between 1 and 30
          </span>
        )}
      </Form.Group>
    </div>
  );
};

export default WeightForm;
