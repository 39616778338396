import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SplashScreen from "./components/splash";
// import Pricing from "./components/pricing/pricing";
import Welcome from "./components/Welcome";
import UserLogin from "./components/userLogin";
import StartPricing from "./components/startPricing";
import Disclaimer from "./components/disclaimer";
import jwt_decode from "jwt-decode";
import { priceIntelligenceInstance } from "../src/config/axios";
import PriceDataProvider from "../src/context";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Userprofile from "./components/Userprofile/Userprofile";
import AuthRoute from "./components/AuthRoute/AuthRoute";
import UserRegister from "./components/userRegister/userRegister";
// import DesktopPricing from "./components/pricing/desktopPricing";
import DesktopResult from "./components/result/desktopResult";
import IndexPricing from "./components/pricing/IndexPricing";
import UnAuthRoute from "./components/AuthRoute/UnAuthRoute";
function App() {
  const location = useLocation();
  useEffect(() => {
    const token = localStorage.getItem("price#@user");
    if (token) {
      const { exp } = jwt_decode(token);
      console.log("exp =>", exp);
      if (Date.now() >= exp * 1000) {
        console.log("okkk exppp");
        handleToken();
      }
    }
    if (location.pathname === "/") {
      setTimeout(() => {
        if ( localStorage.getItem('price#@user')&&localStorage.getItem('price#@useremail')) {
           window.location.href = "/start-pricing";
        } else {
           window.location.href = "/user-login";
        }
        // window.location.href = "/start-pricing";
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToken = async () => {
    const refreshtoken = localStorage.getItem("price@refreshtoken");
    try {
      const token = {
        tokenid: refreshtoken,
      };
      const response = await priceIntelligenceInstance().post(
        "users/v2/getToken",
        token
      );
      const { status, token: usertoken } = response.data || {};
      if (status === 200) {
        localStorage.setItem("price#@user", usertoken);
      }
    } catch (e) {
      console.log("refresh erro =>", e);
    }
  };

  // const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/start-pricing') {
      document.body.classList.add('disclaimer-active');
    } 
    else {
      document.body.classList.remove('disclaimer-active');
    }

    // Clean up on unmount
    return () => {
      document.body.classList.remove('disclaimer-active');
    };
  }, [location]);

  return (
    <>
      <PriceDataProvider>
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/welcome" element={<UnAuthRoute component={<Welcome />} />} />
          <Route path="/user-login" element={<UnAuthRoute component={<UserLogin />} />} />
          <Route
            path="/start-pricing"
            element={<AuthRoute component={<StartPricing />} />}
          />
          <Route
            path="/disclaimer"
            element={<AuthRoute component={<Disclaimer />} />}
          />
          {/* <Route
            path="/pricing"
            element={<AuthRoute component={<Pricing />} />}
          /> */}
          {/* <Route
            path="/desktop-pricing"
            element={<AuthRoute component={<DesktopPricing />} />}
          /> */}
           <Route
            path="/pricing"
            element={<AuthRoute component={<IndexPricing />} />}
          />
          <Route
            path="/result"
            element={<AuthRoute component={<DesktopResult />} />}
          />
          {/* <Route path="/desktop-result" element={<AuthRoute component={<DesktopResult/>} />} /> */}
          <Route path="/profile" element={<Userprofile />} />
          <Route path="/user-register" element={<UserRegister />} />
        </Routes>
      </PriceDataProvider>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        style={{ width: "400px" }}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
