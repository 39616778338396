import axios from 'axios';
import { serverPath } from './key';
export const withoutAuth = () => {
  return axios.create({
    baseURL: `${serverPath}`,
  });
};
export const priceIntelligenceInstance = () => {
  return axios.create({
    baseURL: serverPath,
    headers: {
      Authorization: localStorage.getItem('price#@user')
        ? `${'Bearer '}${localStorage.getItem('price#@user')}`
        : '',
    },
    timeout: 1000 * 10000,
  });
};
