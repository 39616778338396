import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PriceIntelligenceLayout from "..";
import Header from "../header";
import { Button } from "react-bootstrap";
import arrow from "../../assets/images/pricing/arrow.svg";
// import pricearrow from "../../assets/images/pricing/price-arrow.svg";
import { priceIntelligenceInstance } from "../../config/axios";
import { PriceContext } from "../../context";
import Sidebar from "../sidebar";
import barIcon from "../../assets/images/pricing/menu-icon-dark.svg";
import { checkGIAReport, isShapeRound } from "../../utils/function";
import Home from "./home";
import { diamondPolish, diamondSymmetry } from "../../utils/diamondData";
import ErrorModal from "../ErrorModal/errorModal";

const StartPricing = () => {
  const getContext = useContext(PriceContext);
  const {
    // pricestepData,
    setPricestepData,
    setGiaReport,
    setIsManualPricing,
    setWidthValue,
    setLengthValue,
    setReportType,
    checkAssets, setCheckAssets,
    // reportType
  } = getContext;
  const [loading, setLoading] = useState(false);
  const [reportnum, setReportnum] = useState("");
  const [show, setShow] = useState(false);
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [showButton, setShowButton] = useState(true);
  // const [msgSuccess, setMsgSuccess] = useState();
  useEffect(() => {
    setCheckAssets(localStorage.getItem("checkAssets") === "true" ? true : false);
    // console.log("checkAssets", checkAssets);
    // eslint-disable-next-line
  }, []);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [GiaNum, setGiaNum] = useState(false);
  const [errMsg , setErrMsg ] = useState("");
  const [ errorModal, setErrorModal ] = useState(false);

  let navigate = useNavigate();
  console.log("disabled", buttonDisable , reportnum);

  useEffect(() => {
    setCheckAssets(localStorage.getItem("checkAssets") === "true" ? true : false);
     // eslint-disable-next-line
  }, []);

  const handleShowReport = async () => {
    try {
      setButtonDisable(true);
      setReportType("byGia");
      setLoading(true);
      if (reportnum !== "" || reportnum !== " ") {
        const Id = reportnum;

        const response = await priceIntelligenceInstance().get(
          `/price/getGIAReport/${Id}`
        );
        const { data, autoInputData } = response?.data;
        const { msg, code } = data || {};
        const { width, length} = autoInputData || {}
        console.log("data ==>", { data, autoInputData });
        if (code === 200) {
          setGiaReport(data);
          const isRoundShapeCheck = isShapeRound(autoInputData.shape);
          const polish = diamondPolish?.find((s) => s.displayName === autoInputData.polish)?.code;
          const symmetry = diamondSymmetry?.find((s) => s.displayName === autoInputData.symmetry)?.code;
          const newData = {
            giaReportNumber: reportnum,
            ratio: isRoundShapeCheck ? "1.00" : autoInputData?.ratio,
            length: isRoundShapeCheck ? 1 : autoInputData?.length,
            width: isRoundShapeCheck ? 1 : autoInputData?.width,
            polish,
            symmetry,
          };
          const idu = {
            colorInnerGrade: "2",
            colorDispersion: "3",
            colorUndertone: "3",
          };
          if (checkAssets ){
            setPricestepData({ ...autoInputData, ...newData  ,  ...idu, singlePair : "single"  });
          }else{
            setPricestepData({ ...autoInputData, ...newData  });
          }
          setLengthValue(length);
          setWidthValue(width);
          setShowButton(false);
          setIsManualPricing(false);
          setShowNextBtn(true);
          const checkPass = checkGIAReport(
            { ...autoInputData, ...newData },
            toast,
            setErrMsg,
          );
          console.log(checkPass);
          if (!checkPass) {
            setButtonDisable(false);
            setLoading(false);
            setGiaNum(false);
            setErrorModal(true);
            return;
          }


          navigate(`${"/pricing"}`, {
            state: { action: "fromstartpricing" , priceData : checkAssets ? { ...autoInputData, ...newData  ,  ...idu , singlePair : "single" } : { ...autoInputData, ...newData } },
          });
         
        } else {
          setButtonDisable(false);
          setLoading(false);
          setErrMsg(msg);
          setErrorModal(true);

          // toast.error(msg, { toastId: "giareport" });
        }
      } else {
        setButtonDisable(false);
        setLoading(false);
        setGiaNum(false);
        setErrorModal(true);
        setErrMsg("Please enter GIA number");
        // toast.error("Plese enter GIA number", { toastId: "gianum" });
      }
    } catch (e) {
      setButtonDisable(false);
      setLoading(false);

    }
  };

 





  const handleOnchange = async (val) => {
    setReportnum(val);
    if (val.length >= 7 && val.length <= 10) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };
  const routeChangeManual = () => {
    setReportType("byManual");
    navigate(`${"/pricing"}`, {
      state: { action: "fromstartmanualpricing" },
    });
  };

  const handleGia = () => {
    setGiaNum(true);
    setShowNextBtn(true);
  };

  const handleChecked = () => {
    setCheckAssets(!checkAssets);
    localStorage.setItem("checkAssets", !checkAssets);
  };

  const BackTohome = () => {
    setButtonDisable(true);
    setGiaNum(false);
    setReportnum("");
   
  };

  return (
    <PriceIntelligenceLayout>
      <div className="start-pricing">
        <Header />
        <div className="start-pricing-content start-pricing-mob-content">
          <div className="pricing-entrence-content-img  start-pricing-text">
           {/* <span className=>[Beta]</span> */}
           <div className="betaPrice">
           <p>[Beta]</p>
            <h1>build a price</h1>
            </div>
          {!GiaNum ?  <h3>Yellow, Pink and Blue 1 to 30 carat</h3> : 
          // eslint-disable-next-line
          <h3></h3>}  
            <Button className="menu-toggle menu-toggle-mob" onClick={handleShow}>
              <img src={barIcon} alt="menu" />
            </Button>
            <Sidebar show={show} handleClose={handleClose} />
          </div>
          {!loading && (
            <div className="start-pricing-form ">
              <div className="start-pricing-formbox">
                <div
                  className={`start-pricing-inpbox ${
                    !showButton ? "gia-number-input" : ""
                  }`}
                >
                  {GiaNum && (
                    <>
                      <input
                        type="number"
                        placeholder="GIA REPORT #"
                        value={reportnum}
                        onChange={(e) => handleOnchange(e.target.value)}
                        autoFocus
                        className="w-100"
                      />
                    
                    </>
                  )}
                </div>
                
              

                {showNextBtn && !loading && GiaNum && (
                  <div className="gia-summary-result gia_result_updated">
                    <div className="pricing-userlogin-content-btn ">
                      <Button
                        onClick={handleShowReport}
                        disabled={buttonDisable }
                      >
                        Next <img src={arrow} alt="arrow" />{" "}
                      </Button>
                    </div>
                    <div className="check_value_summary">
                      {checkAssets && <p>Visual assets are not applied</p>}
                      <Button className="back_btn" onClick={BackTohome}>
                        {" "}
                        <img src={arrow} alt="arrow" /> Back{" "}
                      </Button>
                    </div>
                  </div>
                )}
                {!GiaNum && (
                  <Home
                    handleChecked={handleChecked}
                    checkAssets={checkAssets}
                    handleGia={handleGia}
                    routeChangeManual={routeChangeManual}
                  />
                )}
               <h4  className="confidential-mobile-text"> Confidential </h4> 
              </div>
              
             
          
            </div>
          )}

          {/* for loader */}
          {loading && (
            <div className="start-pricing-loader text-light">
              <div className="spinner"></div>
              {/* <p>Fetching GIA report...</p> */}
            </div>
          )}
        </div>
        <div className="start-pricing-content start-pricing-desktop-content">

          <div className="pricing-entrence-banner-img">
          <div className="pricing-entrence-content-bottom pricing-entrence-button">
          <div className="pricing-entrence-banner-top  pricing-entrence-banner-img1  start-pricing-text1 ">
           
          <div className={`betaPrice ${!GiaNum ? "betaGia" : ""}`}>
           <p>[Beta]</p>
            <h1>build a price</h1>
            </div>
            {/* for loader */}
          {loading && (
            <div className="start-pricing-loader text-light">
              <div className="spinner"></div>
              {/* <p>Fetching GIA report...</p> */}
            </div>
          )}
         {!GiaNum ?  <h3>Yellow, Pink and Blue 1 to 30 carat</h3> : 
         // eslint-disable-next-line
         <h3></h3>}  
           <Button className="menu-toggle menu-toggle-mob" onClick={handleShow}>
             <img src={barIcon} alt="menu" />
           </Button>
           {/* <Sidebar show={show} handleClose={handleClose} /> */}
         </div>
          {!loading && (
            <div className="start-pricing-form w-100 bg-transparent">
              <div className="start-pricing-formbox">
                <div
                  className={`start-pricing-inpbox ${
                    !showButton ? "gia-number-input" : ""
                  }`}
                >
                  {GiaNum && (
                    <>
                      <input
                        type="number"
                        placeholder="GIA REPORT #"
                        value={reportnum}
                        onChange={(e) => handleOnchange(e.target.value)}
                        autoFocus
                        className="w-100"
                      />
                    
                    </>
                  )}
                </div>
              

                {showNextBtn && !loading && GiaNum && (
                  <div className="gia-summary-result gia_result_updated">
                    <div className="pricing-userlogin-content-btn ">
                      <Button
                        onClick={handleShowReport}
                        disabled={buttonDisable }
                      >
                        Next <img src={arrow} alt="arrow" />{" "}
                      </Button>
                    </div>
                    <div className="check_value_summary">
                      {checkAssets && <p>Visual assets are not applied</p>}
                      <Button className="back_btn" onClick={BackTohome}>
                        {" "}
                        <img src={arrow} alt="arrow" /> Back{" "}
                      </Button>
                    </div>
                  </div>
                )}
                {!GiaNum && (
                  <Home
                    handleChecked={handleChecked}
                    checkAssets={checkAssets}
                    handleGia={handleGia}
                    routeChangeManual={routeChangeManual}
                  />
                )}
               
              </div>
             
              <h4  className="confidential-desktop-text"> Confidential </h4>
            </div>
          )}
          </div>
          </div>
       

         
        </div>


      </div>
      <ErrorModal
        errorModal={errorModal}
        setErrorModal={setErrorModal}
        errMsg={errMsg}
        // infoTxt={infoTxt}
        bg_remove_modal={"bg_remove_modal"}
      />
    </PriceIntelligenceLayout>
    

  );
};

export default StartPricing;
