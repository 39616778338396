import React, { useContext } from 'react'
import {  Button, Modal } from 'react-bootstrap'
import closeIcon from "../../assets/images/pricing/cross-icon.svg";
import {  FloatingLabel, Form } from "react-bootstrap";
import { priceIntelligenceInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { PriceContext } from "../../context";
const UpdateProfile = ({UpdateProfileModal,handleUpdateProfileModal,setPayload,payload}) => {
  const getContext = useContext(PriceContext);
   const { setUserDetails } = getContext;
  
  
const handleOnChange = (e) => {
  const { name, value } = e.target;
  setPayload({ ...payload, [name]: value });
  };

// console.log("+++++++++++++",userDetails);

const handleSubmit = async (e) => {
  try {
    const response = await priceIntelligenceInstance().put(
      "/users/v2/updatepiuser",
      payload
    );
    const { status, message,userDetails } = response.data || {};
    if (status === 200) {
      toast.success(message, { toastId: "loginsuccess" });
      setUserDetails(userDetails)
      handleUpdateProfileModal()
      return;
    }
    
    toast.error(message, { toastId: "loginerror" });
    
  } catch (e) {
    // console.log(e.response.data.msg);
    if(e.response.data.msg==="Unauthorized Access"){
      toast.error(e.response.data.msg, { toastId: "loginerror" })
    }
else{
 e.response.data.message.map((item,index)=>{
      return toast.error(item, { toastId: "loginerror" });
    })
}
    
    
  }
};
  return (
    <Modal
    show={UpdateProfileModal}
    onHide={handleUpdateProfileModal}
    centered
   
  >
    <Modal.Header>
    <div className='d-flex w-100 justify-content-between'>
        <h5>Update Profile</h5>
    <div onClick={handleUpdateProfileModal}>
        
          <img src={closeIcon} alt="icon"  />
        
      </div>
      </div>
      </Modal.Header>
    <Modal.Body>
    <div className="pricing-userlogin-content-input">
              <FloatingLabel
                
                label="Email address *"  
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  name="email"
                  disabled
                  value={payload.email}
                  onChange={(e) => handleOnChange(e)}   
                />
              </FloatingLabel>
              <Form.Text className="d-none">
                  Please enter valid email
                </Form.Text>
              <div>
              <FloatingLabel
               
                label="Phone Number *"
                
              >
                <Form.Control
                  type="text"
                  placeholder="phone number"
                  name="phone"
                  disabled
                  value={payload.phone}
                  onChange={(e) => handleOnChange(e)}  
                />
               
              </FloatingLabel>
              <Form.Text className="d-none">
                  Please enter valid Phone Number
                </Form.Text>
              </div>
              <div>
                <FloatingLabel
               
                label="First Name *"
              >
                <Form.Control
                  type="text"
                  placeholder="first name"
                  name="firstName"
                 
                  value={payload.firstName}
                onChange={(e) => handleOnChange(e)}  
                />
              </FloatingLabel>
              <Form.Text className="d-none">
                  Please enter valid First Name
                </Form.Text>
              </div>
              <div>
              <FloatingLabel
                
                label="Last Name *"          
              >
                <Form.Control
                  type="text"
                  placeholder="last name"
                  name="lastName"
                  
                  value={payload.lastName}
                  onChange={(e) => handleOnChange(e)} 
                /> 
              </FloatingLabel>
              <Form.Text className="d-none">
                  Please enter valid Phone Number
                </Form.Text>
              </div>
              
            </div>
<div className='w-100 d-flex justify-content-center'>
      <Button onClick={() => handleSubmit()} className="updateButton mt-4" ><span className='mt-1'>Update</span></Button> 
      </div>   
    </Modal.Body>
  </Modal>
  )
}

export default UpdateProfile