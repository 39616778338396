const NotVisibleIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33337 15.9993C1.33337 15.9993 6.91737 7.99935 16 7.99935C17.2627 7.99935 18.452 8.16068 19.5707 8.42868L11.1467 16.8527C10.8322 16.1665 10.6686 15.4208 10.6667 14.666C10.6667 13.4167 11.1134 12.2833 11.8334 11.374C8.64404 12.4567 6.21471 14.554 4.82804 15.9993C6.0093 17.2318 7.34479 18.3064 8.80137 19.1967L6.86404 21.134C3.32937 18.8393 1.33337 15.9993 1.33337 15.9993ZM30.6667 15.9993C30.6667 15.9993 25.0827 23.9993 16 23.9993C14.7387 23.9993 13.552 23.834 12.432 23.5687L6.66671 29.3327L4.66671 27.3327L27.3334 4.66602L29.3334 6.66602L25.1374 10.862C28.668 13.158 30.6667 15.9993 30.6667 15.9993ZM27.18 16.0073C25.9967 14.7715 24.658 13.6945 23.1974 12.8033L21.3334 14.666C21.3334 16.0805 20.7715 17.4371 19.7713 18.4373C18.7711 19.4374 17.4145 19.9993 16 19.9993L14.7374 21.262C15.1507 21.3033 15.5694 21.3327 16 21.3327C21.304 21.3327 25.2574 18.0287 27.18 16.0073Z"
        fill="#6a77a2"
      />
    </svg>
  );
};

export default NotVisibleIcon;
