import React from "react";
import "./style.css";

const PriceIntelligenceLayout = ({ children }) => {
  return (
    <>
      <span className="price-intelligence-layout-text d-none">
        For Better Experience Switch to Mobile and Tablet view
      </span>
      <div className="price-intelligence-layout">{children}</div>
    </>
  );
};

export default PriceIntelligenceLayout;
