import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PriceIntelligenceLayout from "..";
import Header from "../header";
const Welcome = () => {
  const navigate=useNavigate()
  return (
    <PriceIntelligenceLayout>
      <div className="pricing-entrence">
        <Header />
        <div className="pricing-entrence-content">

        <div className="pricing-entrence-content-mob pricing-entrence-content">
          <div className="pricing-entrence-content-img">
            <h1 className="price-intelligence-text">Price Intelligence</h1>
           
          </div>
           <p className="b2b-only-text">B2B Only</p>
          <div className="pricing-entrence-content-bottom pricing-entrence-button">
            {/* <Button onClick={()=>{
              navigate("/user-register")
            }} className="pricing-entrence-content-btn">
              user set up
            </Button> */}

            <Button onClick={()=>{
              navigate("/user-login")
            }} className="pricing-entrence-content-btn">
               Log in
            </Button>
            {/* <Link to="/user-login">already a user? Log in</Link> */}
          </div>
        </div>
          <div className="pricing-entrence-banner-img">
           
          <div className="pricing-entrence-content-bottom pricing-entrence-button">
          <h1 className="price-intelligence-text price-header-text">Price Intelligence</h1>
          <p className="b2b-only-text">B2B Only</p>
            {/* <Button onClick={()=>{
              navigate("/user-register")
            }} className="pricing-entrence-content-btn">
              user set up
            </Button> */}

            <Button onClick={()=>{
              navigate("/user-login")
            }} className="pricing-entrence-content-btn">
               Log in
            </Button>
            {/* <Link to="/user-login">already a user? Log in</Link> */}
          </div>
           
          </div>
           
        </div>
      </div>
    </PriceIntelligenceLayout>
  );
};

export default Welcome;
