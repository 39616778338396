export const diamondColors = [
  { id: 1, img: "yellow", name: "Yellow", value: "Yellow" },
  // {
  //   id: 2,
  //   img: "greenish-yellow",
  //   name: "Greenish Yellow",
  //   value: "Greenish Yellow",
  // },
  // {
  //   id: 3,
  //   img: "orange-yellow",
  //   name: "Orange Yellow",
  //   value: "Orange Yellow",
  // },
  {
    id: 4,
    img: "pink",
    name: "Pink (P.Pink)",
    value: "Pink",
  },
  // { id: 5, img: "orangy-pink", name: "Orangy Pink", value: "Orangy Pink" },
  // {
  //   id: 6,
  //   img: "purplish-pink",
  //   name: "Purplish Pink",
  //   value: "Purplish Pink",
  // },
  // {
  //   id: 7,
  //   img: "pinkish-purple",
  //   name: "Pinkish Purple",
  //   value: "Pinkish Purple",
  // },
  // { id: 8, img: "purple", name: "Purple", value: "Purple" },
  // {
  //   id: 9,
  //   img: "brownish-pink",
  //   name: "Brownish Pink",
  //   value: "Brownish Pink",
  // },
  // { id: 10, img: "brown-pink", name: "Brown Pink", value: "Brown Pink" },
  { id: 11, img: "blue", name: "Blue", value: "Blue" },
  // { id: 12, img: "grey-blue", name: "Gray Blue", value: "Gray Blue" },
  // { id: 13, img: "green-blue", name: "Green Blue", value: "Green Blue" },
  // {
  //   id: 14,
  //   img: "greenish-blue",
  //   name: "Greenish Blue",
  //   value: "Greenish Blue",
  // },
  // { id: 15, img: "grayish-blue", name: "Grayish Blue", value: "Grayish Blue" },
  // { id: 16, img: "orange", name: "Orange", value: "Orange" },
  // {
  //   id: 17,
  //   img: "yellow-orange",
  //   name: "Yellow Orange",
  //   value: "Yellow Orange",
  // },
  // {
  //   id: 18,
  //   img: "yellowish-orange",
  //   name: "Yellowish Orange",
  //   value: "Yellowish Orange",
  // },
  // { id: 19, img: "brown-orange", name: "Brown Orange", value: "Brown Orange" },
  // { id: 20, img: "green", name: "Green", value: "Green" },
  // {
  //   id: 21,
  //   img: "yellow-green",
  //   name: "Yellow Green",
  //   value: "Yellow Green",
  // },
  // {
  //   id: 22,
  //   img: "bluegreen",
  //   name: "Blue Green",
  //   value: "Blue Green",
  // },
  // {
  //   id: 23,
  //   img: "bluish-green",
  //   name: "Bluish Green",
  //   value: "Bluish Green",
  // },
  // { id: 24, img: "red", name: "Red", value: "Red" },
  // { id: 25, img: "purplish-red", name: "Purplish Red", value: "Purplish Red" },
  // { id: 26, img: "grey", name: "Gray", value: "Gray" },
  // { id: 27, img: "blue-grey", name: "Blue Gray", value: "Blue Gray" },

  // { id: 27, img: "purple-brown-grey", value: "Purple with Brown or Gray" },
  // { id: 28, img: "grey-violet", value: "Gray-violet - violet gray" },
  // { id: 29, img: "violet-blue", value: "Violet-blue to blue violet" },
  // { id: 30, img: "violet", value: "Violet" },
  // { id: 31, img: "greyish-yellow-green", value: "Grayish yellow-green" },
  // {
  //   id: 32,
  //   img: "brownish-greenish-yellow",
  //   value: "Brownish greenish yellow",
  // },
  // { id: 33, img: "greyish-green-yellow", value: "Grayish green-yellow" },
  // { id: 34, img: "green-grey", value: "Green gray to gray green" },
  // {
  //   id: 35,
  //   img: "greenish-yellow",
  //   name: "Green/ish Yellow",
  //   value: "Green/ish Yellow",
  // },
  // {
  //   id: 36,
  //   img: "purplish-pink",
  //   name: "Purple/ish Pink",
  //   value: "Purple/ish Pink",
  // },
  // {
  //   id: 37,
  //   img: "purplish-pink",
  //   name: "Pink - Purplish Pink",
  //   value: "Pink - Purplish Pink",
  // },
  // {
  //   id: 38,
  //   img: "yellow-green",
  //   name: "Yellow/ish Green",
  //   value: "Yellow/ish Green",
  // },
  // { id: 39, img: "blue-grey", name: "Blue/ish Gray", value: "Blue/ish Gray" },
  // {
  //   id: 40,
  //   img: "pink",
  //   name: "Pink/Purple/ish Pink",
  //   value: "Pink/Purple/ish Pink",
  // },
];

export const diamondSaturations = [
  // "Faint",
  // "Very Light",
  // "Light",
  "Fancy Light",
  "Fancy",
  "Fancy Intense",
  "Fancy Vivid",
  "Fancy Deep",
  // "Fancy Dark",
];

export const diamondShapes = [
  "Cushion",
  "Radiant",
  "Oval",
  "Heart",
  "Pear",
  "Emerald",
  "Round",
];

export const diamondClarity = [
  {
    displayName: "SI2",
    code: "SI2",
    name: "Slightly Included",
  },
  {
    displayName: "SI1",
    code: "SI1",
    name: "Slightly Included",
  },
  {
    displayName: "VS",
    code: "VS",
    name: "Very Slightly Included",
  },
  {
    displayName: "VVS",
    code: "VVS",
    name: "Very, Very Slightly Included",
  },
  {
    displayName: "FL or IF",
    code: "FL or IF",
    name: "Flawless",
  },
];

export const diamondFluorescence = [
  "none",
  "Faint Blue",
  "Medium Blue",
  "Strong Blue",
  "Very Strong Blue",
  "Faint Green",
  "Medium Green",
  "Strong Green",
  "Very Strong Green",
  "Faint Yellow",
  "Medium Yellow",
  "Strong Yellow",
  "Very Strong Yellow",
  "Faint Orange",
  "Medium Orange",
  "Strong Orange",
  "Very Strong Orange",
];

export const diamondFluorescenceColor = [
  "None",
  "Blue",
  "Green",
  "Yellow",
  "Orange",
];
export const diamondFluorescenceColorSub = [

  "Faint" ,
  "Medium",
  "Strong",
  "Very Strong",

];



// export const diamondDepth = [
//   {
//     displayName: "0-58",
//     depthName: "4D+",
//     Id:"1"
    
//   },
//   {
//     displayName: "59-63",
//     depthName: "4D",
//     Id:"2"
//   },
//   {
//     displayName: "64-68",
//     depthName: "3D",
//     Id:"3"
//   },
//   {
//     displayName: "69-73",
//     depthName: "2D",
//     Id:"4"
//   },
//   {
//     displayName: "74-77",
//     depthName: "1D",
//     Id:"5"
//   },
// ];

export const diamondDepth = [
  {
    displayName: "Face-Up - Significantly Smaller",
    depthName: "4D+",
    Id:"1"
    
  },
  {
    displayName: "Face-Up - Distinctly Smaller",
    depthName: "4D",
    Id:"2"
  },
  {
    displayName: "Face-Up - Subtly Smaller",
    depthName: "3D",
    Id:"3"
  },
  {
    displayName: "Face-Up - Moderately below",
    depthName: "3D",
    Id:"4"
  },
  {
    displayName: "Face-Up - Slightly Smaller",
    depthName: "2D",
    Id:"5"
  },
  {
    displayName: "Face-Up - Standard",
    depthName: "1D",
    Id:"6"
  },

  {
    displayName: "Face-Up - 1 to 5% larger",
    depthName: "1D",
    Id:"7"
  },
  {
    displayName: "Face-Up - 6 to 15% larger",
    depthName: "1D",
    Id:"8"
  },
  {
    displayName: "Face-Up - 16% or larger",
    depthName: "1D",
    Id:"9"
  },
];


export const diamondDepthForManual = [
  
  {
    displayName: "Face-Up - Small",
    depthName: "3D",
    Id:"4"
  },
 
  {
    displayName: "Face-Up - Standard",
    depthName: "1D",
    Id:"6"
  },
  {
    displayName: "Face-Up - Large",
    depthName: "1D",
    Id:"8"
  },
  
];

export const diamondDepthForRecalculation = [
  
  {
    displayName: "Small",
    depthName: "3D",
    Id:"4"
  },
 
  {
    displayName: "Standard",
    depthName: "1D",
    Id:"6"
  },
  {
    displayName: "Large",
    depthName: "1D",
    Id:"8"
  },
  
];

export const diamondRoundDepth = [
  {
    displayName: "58-63",
    depthName: "2D",
  },
];

export const diamondDesktopPolish = [
  // {
  //   displayName: "Default",
  //   code: "default",
  // },
  {
    displayName: "Excellent",
    code: "Ex",
  },
  {
    displayName: "Very Good",
    code: "Vg",
  },
  {
    displayName: "Good",
    code: "G",
  },
  {
    displayName: "Fair",
    code: "Fair",
  },
  {
    displayName: "Poor",
    code: "Poor",
  },
];

export const diamondPolish = [
  {
    displayName: "Excellent",
    code: "Ex",
  },
  {
    displayName: "Very Good",
    code: "Vg",
  },
  {
    displayName: "Good",
    code: "G",
  },
  {
    displayName: "Fair",
    code: "Fair",
  },
  {
    displayName: "Poor",
    code: "Poor",
  },
];

export const diamondDesktopSymmetry = [
  // {
  //   displayName: "Default",
  //   code: "default",
  // },
  {
    displayName: "Excellent",
    code: "Ex",
  },
  {
    displayName: "Very Good",
    code: "Vg",
  },
  {
    displayName: "Good",
    code: "G",
  },
  {
    displayName: "Fair",
    code: "Fair",
  },
  {
    displayName: "Poor",
    code: "Poor",
  },
];

export const diamondSymmetry = [
  {
    displayName: "Excellent",
    code: "Ex",
  },
  {
    displayName: "Very Good",
    code: "Vg",
  },
  {
    displayName: "Good",
    code: "G",
  },
  {
    displayName: "Fair",
    code: "Fair",
  },
  {
    displayName: "Poor",
    code: "Poor",
  },
];

export const diamondColorInnerGrade = ["1", "2", "3", "4", "4+"];

export const diamondColorDispersion = ["1", "2", "3", "4", "4+"];

export const diamondColorUndertone = [
  {
    value: "1",
    desc: "The Undertone refers to a subtle, yet visible, unflattering hue sensation that is not stated on the GIA report. A noticeable undertone will disassociate the stone from its color description on the report and will entail a discount. For example, In yellow and blue fancy color diamonds, the absence of undertone will lead to a rare absolute color. However, A purple undertone on a pink fancy color diamond will create a thought after color and will deserve a premium.",
  },
  {
    value: "2",
    desc: "The Undertone refers to a subtle, yet visible, unflattering hue sensation that is not stated on the GIA report. A noticeable undertone will disassociate the stone from its color description on the report and will entail a discount. For example, In yellow and blue fancy color diamonds, the absence of undertone will lead to a rare absolute color. However, A purple undertone on a pink fancy color diamond will create a thought after color and will deserve a premium.",
  },
  {
    value: "3",
    desc: "The Undertone refers to a subtle, yet visible, unflattering hue sensation that is not stated on the GIA report. A noticeable undertone will disassociate the stone from its color description on the report and will entail a discount. For example, In yellow and blue fancy color diamonds, the absence of undertone will lead to a rare absolute color. However, A purple undertone on a pink fancy color diamond will create a thought after color and will deserve a premium.",
  },
  {
    value: "4",
    desc: "The Undertone refers to a subtle, yet visible, unflattering hue sensation that is not stated on the GIA report. A noticeable undertone will disassociate the stone from its color description on the report and will entail a discount. For example, In yellow and blue fancy color diamonds, the absence of undertone will lead to a rare absolute color. However, A purple undertone on a pink fancy color diamond will create a thought after color and will deserve a premium.",
  },
  {
    value: "4+",
    desc: "The Undertone refers to a subtle, yet visible, unflattering hue sensation that is not stated on the GIA report. A noticeable undertone will disassociate the stone from its color description on the report and will entail a discount. For example, In yellow and blue fancy color diamonds, the absence of undertone will lead to a rare absolute color. However, A purple undertone on a pink fancy color diamond will create a thought after color and will deserve a premium.",
  },
];

export const DiaryCauseShape = [
  {
    code: 'CB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CCSB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CIRC',
    shape: 'Round',
  },
  {
    code: 'CMB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CRB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CRMB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CRSC',
    shape: 'Emerald',
  },
  {
    code: 'CRX',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CSMB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CSSC',
    shape: 'Emerald',
  },
  {
    code: 'CSX',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'CX',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'EM',
    shape: 'Emerald',
  },
  {
    code: 'HB',
    shape: 'Heart',
  },
  {
    code: 'HMB',
    shape: 'Heart',
  },
  // {
  //   code: 'MB',
  //   shape: 'Marquise',
  // },
  {
    code: 'MHB',
    shape: 'Heart',
  },
  {
    code: 'MHX',
    shape: 'Heart',
  },
  {
    code: 'MMB',
    shape: 'Marquise',
  },
  {
    code: 'MOB',
    shape: 'Oval',
  },
  {
    code: 'MOX',
    shape: 'Oval',
  },
  {
    code: 'MPX',
    shape: 'Pear',
  },
  // {
  //   code: 'PX',
  //   shape: 'Pear',
  // },
  // {
  //   code: 'MRB',
  //   shape: 'Cushion / Radiant',
  // },
  {
    code: 'MMC',
    shape: 'Marquise',
  },
  {
    code: 'MMX',
    shape: 'Marquise',
  },
  {
    code: 'OB',
    shape: 'Oval',
  },
  {
    code: 'PB',
    shape: 'Pear',
  },
  {
    code: 'PMB',
    shape: 'Pear',
  },
  {
    code: 'RCRB',
    shape: 'Cushion / Radiant',
  },
  {
    code: 'RCSB',
    shape: 'Cushion / Radiant',
  },

  {
    code: 'RCSMB',
    shape: 'Cushion / Radiant',
  },

  {
    code: 'SEM',
    shape: 'Emerald',
  },
  {
    code: 'OMB',
    shape: 'Oval',
  },
  {
    code: 'RBC',
    shape: 'Round',
  },
];


export const clarity_T_Value =
 ['SI1',
  'SI2',
  'VS',
  'VVS',
  'IF',
  "FL or IF"
];

export const clarity_T_Value_DESKTOP =
 ['SI2',
  'SI1',
  'VS',
  'VVS',
  "FL or IF"
];

