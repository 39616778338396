import { Button, Modal } from "react-bootstrap";
import "./errorModal.css";
// import closeIcon from "../../assets/images/pricing/cross-icon.svg";

const ErrorModal = ({ errorModal, setErrorModal, errMsg, infoTxt,bg_remove_modal }) => {
  return (
    <Modal
      show={errorModal}
      onHide={() => setErrorModal(!errorModal)}
      centered
      className={`${bg_remove_modal} info-modal back-modal `}
    >
      <div className="close-btn">
        {/* <Button onClick={() => setErrorModal(!errorModal)}>
          <img src={closeIcon} alt="icon" />
        </Button> */}
      </div>
      <Modal.Body>
        <div className="back-modal-body">
          <h4>{errMsg}</h4>
          {/* <div className="info-content">
            {infoTxt.map((text, i) => (
              <>
                <p key={i}>{text}</p>
              </>
            ))}
          </div> */}
          <div className="action-grid mt-3">
            <Button onClick={() => setErrorModal(false)}>close</Button>
          </div>
        </div>{" "}
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
