import { PRICE_INTESITY_ERROR_MSG, SUPPORT_COLOR_ERROR_MSG, WEIGHT_LIMIT_ERROR_MSG } from './const'
import { diamondColors, diamondSaturations, diamondShapes, diamondPolish,clarity_T_Value } from "./diamondData";

export const handleInfoModal = (
  infoModal,
  setInfoModal,
  infoData,
  setInfoData
) => {
  setInfoModal(!infoModal);
  setInfoData(infoData);
};
export const checkGIAReport = (data, toast,  setErrMsg)=>{
  // let {carat_weight, saturation, color, shape, clarity, depth, ratio, fluorescence, polish} = data || {};
  let {carat_weight, saturation, color, shape, clarity,  ratio, fluorescence, polish} = data || {};
  if(!carat_weight || (parseFloat(carat_weight) < 1 || parseFloat(carat_weight) > 30)){
    // toast.error(WEIGHT_LIMIT_ERROR_MSG, { toastId: "GIA-validation" });
    setErrMsg(WEIGHT_LIMIT_ERROR_MSG);
    return false;
  }
  if(!saturation || diamondSaturations?.findIndex((el)=> el === saturation) < 0){
    // toast.error(PRICE_INTESITY_ERROR_MSG(saturation,'Intensity'), { toastId: "GIA-validation" });
    setErrMsg(PRICE_INTESITY_ERROR_MSG(saturation,'Intensity'));
    return false;
  }

  if(!color || diamondColors.findIndex((element)=>element.value === color) < 0){
    // toast.error(SUPPORT_COLOR_ERROR_MSG,{ toastId: "GIA-validation" });
    setErrMsg(SUPPORT_COLOR_ERROR_MSG);
    return false;
  }

  if(!shape || diamondShapes?.findIndex((el)=> el === shape) < 0){
    // toast.error(PRICE_INTESITY_ERROR_MSG(shape,'Shape'),{ toastId: "GIA-validation" });
    setErrMsg(PRICE_INTESITY_ERROR_MSG(shape,'Shape'));
    return false;
  }
  if(!clarity || !clarity_T_Value?.includes(clarity)){
    // toast.error(PRICE_INTESITY_ERROR_MSG(clarity,'Clarity'),{ toastId: "GIA-validation" });
    setErrMsg(PRICE_INTESITY_ERROR_MSG(clarity,'Clarity'));
    return false;
  }

  // console.log("depth =>", depth)
//   if(shape ==='Round'){
//     if((shape ==='Round' && (parseFloat(depth) >= 58 && parseFloat(depth) <= 63))){
//     }else{
//       // console.log("depth", depth)
//       toast.error(PRICE_INTESITY_ERROR_MSG(depth,'true face-up'),{ toastId: "GIA-validation" });
//       return false;
//     }
//   }else{
//   if(!depth || Number(depth) > 77){
//     console.log("okkkkkkkkkk")
//     toast.error(PRICE_INTESITY_ERROR_MSG(depth,'Depth'),{ toastId: "GIA-validation" });
//     return false;
//   }
// }

  if(!ratio || ((Number(ratio)< 0.70 && Number(ratio)> 2.00 ) && shape !=='Round')){
      // toast.error(PRICE_INTESITY_ERROR_MSG(ratio,'Ratio'),{ toastId: "GIA-validation" });
      setErrMsg(PRICE_INTESITY_ERROR_MSG(ratio,'Ratio'));
      return false;
  }

  if(!fluorescence || fluorescence?.toLowerCase().includes('white')){
    // toast.error(PRICE_INTESITY_ERROR_MSG(fluorescence,'Fluorescence'),{ toastId: "GIA-validation" });
    setErrMsg(PRICE_INTESITY_ERROR_MSG(fluorescence,'Fluorescence'));
    return false;
    }

  if(!polish || diamondPolish?.findIndex((element)=>element.code.toLowerCase() === polish.toLowerCase()) < 0){
    // toast.error(PRICE_INTESITY_ERROR_MSG(polish,'Polish'),{ toastId: "GIA-validation" });
    setErrMsg(PRICE_INTESITY_ERROR_MSG(polish,'Polish'));
    return false;
    }

  return true;
}

 const formatString = (word)=> {
  if (word?.toLowerCase() ==='excellent') {
    return 'Ex';
  } else {
    const splitWord = word?.split(' ');
    if(splitWord.length ===2){
      return `${splitWord[0]?.charAt(0)?.toUpperCase()}${splitWord[1]?.charAt(0)?.toLowerCase()}`
    }else{
      return `${splitWord[0]?.charAt(0)?.toUpperCase()}`
    }
  }
}

export const getPolishAndSymmetryValue = (polish,symmetry)=>{
  if(polish?.includes('Fair') || symmetry?.includes('Fair')){
    return 'Fair';
  }
  if(polish?.includes('Poor') || symmetry?.includes('Poor')){
    return 'Poor';
  }
  if(polish?.length <=2 && symmetry?.length <=2){
    return `${polish},${symmetry}`;
  }
  const formattedString1 = formatString(polish);
  const formattedString2 = formatString(symmetry);
  //const result = `${formattedString2},${formattedString1}`;
  const result = `${formattedString1},${formattedString2}`;
  return result
}

const commaFormatter = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculateThreePercentLess = (givenNumber ,checkAssets  ) => {
let minPrice = 0
let maxPrice = 0
// console.log("checkAssets",checkAssets , reportType  , (checkAssets && reportType ==='byGia'))
  if(checkAssets){
    minPrice = givenNumber * 0.95
    maxPrice = givenNumber * 1.05
  }else{
    minPrice = givenNumber * 0.97
    maxPrice = givenNumber * 1.03
  }
  if (minPrice > 0 && minPrice < 10000){
    minPrice = parseInt(minPrice / 50) * 50 + 50
  }else if (minPrice > 10000 && minPrice <1000000){
    minPrice = parseInt(minPrice / 500) * 500 + 500
  }else if (minPrice >= 1000000){
    minPrice = parseInt(minPrice / 5000) * 5000 + 5000
  }

  if (maxPrice > 0 && maxPrice < 10000){
    maxPrice = parseInt(maxPrice / 50) * 50 + 50
  }else if (maxPrice >= 10000 && maxPrice <1000000){
    maxPrice = parseInt(maxPrice / 500) * 500 + 500
  }else if (maxPrice >= 1000000){
    maxPrice = parseInt(maxPrice / 5000) * 5000 + 5000
  }
  return `$${commaFormatter(Math.round(minPrice))}-$${commaFormatter(Math.round(maxPrice))}`;
  
};

export const isShapeRound =(shape)=>{
  if(shape==='Round') return true;
  return false
}
