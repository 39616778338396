const VisibleIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8C6.91737 8 1.33337 16 1.33337 16C1.33337 16 6.91737 24 16 24C25.0827 24 30.6667 16 30.6667 16C30.6667 16 25.0827 8 16 8ZM16 21.3333C10.7427 21.3333 6.76537 18.0213 4.82804 16C6.21471 14.5533 8.64404 12.4573 11.8334 11.3747C11.0823 12.3079 10.6709 13.4688 10.6667 14.6667C10.6667 16.0812 11.2286 17.4377 12.2288 18.4379C13.229 19.4381 14.5856 20 16 20C17.4145 20 18.7711 19.4381 19.7713 18.4379C20.7715 17.4377 21.3334 16.0812 21.3334 14.6667C21.3292 13.4688 20.9178 12.3079 20.1667 11.3747C23.3574 12.4573 25.788 14.5547 27.172 16C25.232 18.024 21.256 21.3333 16 21.3333Z"
        fill="#6a77a2"
      />
    </svg>
  );
};

export default VisibleIcon;
