import React, { useState,useContext, useEffect } from 'react'
import PriceIntelligenceLayout from '..'
import './userprofile.css'
import profilepic from '../../assets/images/pricing/dummyProfile.png' 
import { Button } from 'react-bootstrap'
import editIcon from '../../assets/icon/editIcon'
import UpdatePassword from '../UpdateProfileModal/UpdatePassword'
import UpdateProfile from '../UpdateProfileModal/UpdateProfile'
import { PriceContext } from "../../context";
import { priceIntelligenceInstance } from "../../config/axios";
import Loader from '../loader/loader'
import Header from '../header/header'
const Userprofile = () => {
  const getContext = useContext(PriceContext);
    const [UpdatePasswordModal, setUpdatePasswordModal] = useState(false);
    const [UpdateProfileModal, setUpdateProfileModal] = useState(false);
    const [loading, setLoading] = useState(true);
    
    const { userDetails,setUserDetails } = getContext;

    const [payload, setPayload] = useState({
      email: userDetails.email || '',
      phone: userDetails.phone || '',
      firstName: userDetails.firstName || '',
      lastName: userDetails.lastName || '',
     
    });

   
    const handleUpdatePasswordModal = () => {
        setUpdatePasswordModal(!UpdatePasswordModal);
      };

      const handleUpdateProfileModal = () => {
        setUpdateProfileModal(!UpdateProfileModal);
      };

      const getUserDetails = async () => {
        try {
          const response = await priceIntelligenceInstance().get(
            "/users/v2/piUserGet",
            );
          const { status, msg,user } = response.data || {};
          if (status === 200) {
            console.log(msg);
            setUserDetails(user)
            localStorage.setItem("price#@useremail", user?.email);
            setLoading(false)
            return;
          }
           } catch (e) {
          console.log(e);
           }
      };
useEffect(()=>{
  getUserDetails()
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[])
useEffect(()=>{
  setPayload(userDetails)
},[userDetails])

function formatJoinDate(dateString) {
  const options = { month: 'long', year: 'numeric' };
  const joinDate = new Date(dateString);
  const formattedDate = joinDate.toLocaleDateString('en-US', options);

  return `Joined ${formattedDate}`;
}

  return (
    <PriceIntelligenceLayout>
      <div className="pricing-userlogin min-vh-100">
      {loading && <Loader text={"Loading..."}/>}
        <Header title={true}/>
        <div className=" mt-4 mb-4 p-3 d-flex justify-content-center">
   <div className="profileCard  p-4">
      <div className=" image d-flex flex-column justify-content-center align-items-center">
         <div > <img src={profilepic} alt="profile" height="100" width="100" /></div>
         <div> 
        <p className='m-0 mt-3 justify-content-center d-flex gap-1'>

        <span className="name">{userDetails?.firstName}</span> 
         <span className="name">{userDetails.lastName}</span> 
        </p>
        <div className=" text-center mt-0  joined "> <span >{formatJoinDate(userDetails.JoinDate)}</span> </div>
        </div>
       <div className='w-100 mt-4'>

       
        <div className='w-100 my-2'>
        <h6 className="mb-0">First Name</h6>
        <p>{userDetails?.firstName}</p>
        </div>

        <div className='w-100 my-2'>
        <h6 className="mb-0">Last  Name</h6>
        <p>{userDetails.lastName}</p>
        </div>

        <div className='w-100 my-2'>
        <h6 className="mb-0">Phone</h6>
        <p>{userDetails.phone}</p>
        </div>

        <div className='w-100 my-2'>
        <h6 className="mb-0">Email</h6>
        <p>{userDetails.email}</p>
        </div>

        <div className='w-100 d-flex my-2'>
            <div className='w-100'>
        <h6 className="mb-0">Password</h6>
        <p>*********</p>
        </div>
        <p className='changebutton' onClick={handleUpdatePasswordModal}>Change</p>
        </div>
        
    </div>
       <Button className="updateButton" onClick={handleUpdateProfileModal}><spn>{editIcon()}</spn><span className='mt-1'>Edit</span></Button>    
    </div>
   </div>
</div>
<UpdatePassword
UpdatePasswordModal={UpdatePasswordModal}
handleUpdatePasswordModal={handleUpdatePasswordModal}
/>
<UpdateProfile
UpdateProfileModal={UpdateProfileModal}
handleUpdateProfileModal={handleUpdateProfileModal}
payload={payload}
setPayload={setPayload}
/>
      </div>
    </PriceIntelligenceLayout>
  )
}

export default Userprofile