import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import closeIcon from "../../assets/images/pricing/cross-icon.svg";
import "./backModal.css";

const BackModal = ({ backModal, handleBackModal }) => {
  const handleBack = () => {
    window.location.href="/start-pricing";
  };
  return (
    <Modal
      show={backModal}
      onHide={handleBackModal}
      centered
      className="back-modal"
    >
      <div className="close-btn">
        <Button onClick={handleBackModal}>
          <img src={closeIcon} alt="icon" />
        </Button>
      </div>
      <Modal.Body>
        <div className="back-modal-body">
          <h4>attention!</h4>
          <p>you are going to leave the process </p>
          <div className="action-grid">
            <Button className="modal-back-btn" onClick={handleBackModal}>
              cancel
            </Button>
            <Button className="modal-back-btn" onClick={handleBack}>
              proceed
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BackModal;
