import React from 'react';
import {  Navigate } from 'react-router-dom';

const AuthRoute = ({ component: Component, }) => {
  
        if ( localStorage.getItem('price#@user')&&localStorage.getItem('price#@useremail')) {
            return Component;
          } else {
            return <Navigate to="/user-login"  />;
          }
    
}

export default AuthRoute;