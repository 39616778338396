import React from 'react'
import closeIcon from "../../assets/images/pricing/cross-icon.svg";
import {  Button, Modal } from 'react-bootstrap'
const WarningModal = ({warningModaldata,handleWarningModal,text}) => {
  
  return (
    <Modal
    show={warningModaldata}
    onHide= {handleWarningModal}
    centered
    className="info-modal back-modal"
  >
    <div className="close-btn py-3 px-4">
      <Button onClick={handleWarningModal}>
        <img src={closeIcon} alt="icon" />
      </Button>
    </div>
    <Modal.Body className='pb-3'>
      <div className='d-flex justify-content-center'>
    <AlertIcon />
    </div>
        
        <div className="info-content text-center ">
        {/* <span>
        please contact the main user at
              </span> */}
              <span>
              please contact support at
              </span>
              <div className='login-alert-content-wrap'>
                <a href='mailto:customers@fcresearch.org'>
                  customers@fcresearch.org
                </a>
              </div>
             
        </div>
       
      
    </Modal.Body>
  </Modal>
  )
}

export default WarningModal


const AlertIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='80'
      height='80'
      viewBox='0 0 80 80'
    >
      <g fill='none' fillRule='evenodd'>
        <g fill='#FA3782'>
          <g>
            <g>
              <path
                d='M40 7.5l37.5 65h-75L40 7.5zm0 6.354L7.985 69.346h64.028L40 13.854zm1.592 16.185v25.16h-3.184v-25.16h3.184z'
                transform='translate(-680 -365) translate(492 301) translate(188 64)'
              />
              <path
                fillRule='nonzero'
                d='M43.192 58.34L36.808 58.34 36.808 64.638 43.192 64.638z'
                transform='translate(-680 -365) translate(492 301) translate(188 64)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};