import React from "react";
import { Navigate } from "react-router-dom";

const UnAuthRoute = ({ component: Component }) => {
  const valid =
    localStorage.getItem("price#@user") &&
    localStorage.getItem("price#@useremail");
  if (!valid) {
    return Component;
  } else {
    return <Navigate to="/start-pricing" />;
  }
};

export default UnAuthRoute;
