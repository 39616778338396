import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { diamondSaturations } from "../../utils/diamondData";
import { PriceContext } from "../../context";

const SaturationForm = ({ name }) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData, reportType } = getContext;
  const { saturation } = pricestepData || {};

  const [show, setShow] = useState(false);
  const [defaultSaturation, setDefaultSaturation] = useState(
    saturation ? saturation : "Select"
  );

  useEffect(() => {
    setDefaultSaturation(saturation ? saturation : "Select");
  } , [saturation]);


  const handleModal = () => {
    if (reportType === "byGia") {
      return;
    }
    setShow(!show);
  };
  const handleSelect = (value, name) => {

    setDefaultSaturation(value);
    setPricestepData({ ...pricestepData, [name]: value , 
      colorInnerGrade : pricestepData?.colorInnerGrade ==="4+" ? "": pricestepData?.colorInnerGrade
    });
    setShow(false);
  };
  return (
    <>
      <div className="form-wrapper">
        <div
          className={`${reportType ==="byGia"? "disable_input":""} select-section`}
          onClick={handleModal}
          role="presentation"
          style={{
            opacity: `${pricestepData[name] === "" ? "0.5" : "1"}`,
          }}
        >
          <h6>{defaultSaturation}</h6>
        </div>
      </div>
      <SaturationSelector
        show={show}
        handleModal={handleModal}
        handleSelect={handleSelect}
        name={name}
      />
    </>
  );
};

export default SaturationForm;

const SaturationSelector = ({ show, handleModal, handleSelect, name }) => {
  return (
    <Modal
      className="pi-input-selector"
      show={show}
      onHide={handleModal}
      backdropClassName="backdrop-input-bg"
      centered
    >
      <Modal.Body>
        <div className="input-option-wrapper">
          {diamondSaturations.map((el, i) => (
            <div
              className="pi-option-elem"
              onClick={() => handleSelect(el, name)}
              key={i}
            >
              {el}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
