import React, { useState } from 'react'
import infoIcon from "../../assets/images/pricing/info-icon.svg";
import { Button } from 'react-bootstrap';
import InfoModal from '../infoModal/infoModal';
import { DefaultIduData } from '../../utils/dataPI';
const DefaultIdu = ({payload}) => {
  const [openModal,setOpenModal]=useState(false)
  const [heading,setHeading]=useState("");
  const [modalInfo,setModalInfo]=useState([]);
  const handleModalData=(data)=>{
    setOpenModal(true)
    DefaultIduData.forEach((item)=>{
      if(item.field===data){
      setHeading(item.field)
      setModalInfo(item.info);
      }
    })
  }
  return (
    <div className='dafaultIdu_outer'>
      <div><Button onClick={()=>{handleModalData("color Inner-Grade")}}><img src={infoIcon} alt="" /></Button><p >Color Inner-Grade:</p><p>{payload.colorInnerGrade}</p></div>
      <div><Button onClick={()=>{handleModalData("color Dispersion")}}><img src={infoIcon} alt="" /></Button><p className='dafaultIdu_content_wd'>Color Dispersion:</p><p>{payload.colorDispersion}</p></div>
      <div><Button onClick={()=>{handleModalData("color Undertone")}}><img src={infoIcon} alt="" /></Button><p className='dafaultIdu_content_wd'>Color Undertone:</p><p>{payload.colorUndertone}</p></div>
       <InfoModal
          infoModal={openModal}
          setInfoModal={setOpenModal}
          heading={heading}
          infoTxt={modalInfo}
        />
    </div>
  )
}
export default DefaultIdu