import { useState, useEffect } from "react";
import "./pricing.css";
// import { PriceContext } from "../../context";

const DesktopPairType = ({
  pricestepData,
  setPricestepData,
  setDisableSelect,
  from,
}) => {
  // const getContext = useContext(PriceContext);
  // const { setPricestepData, pricestepData } = getContext;
  const [activeTab, setActiveTab] = useState(false);
  const [activeTabName, setActiveTabName] = useState("single");
  // console.log("name =", pricestepData?.singlePair)

  // console.log("activeTabName =", activeTabName);
  // const checkAssets =
  //   localStorage.getItem("checkAssets") === "true" ? true : false;
  // console.log("activeTab =", activeTab);
  // const [disableDropbox , setDisableDropbox] = useState(false)
  const [disable_border] = useState(false);

  const handleTab = (name) => {
    // console.log("name =", name)

    if (
      !pricestepData?.colorUndertone
    ) {
      // setDisable_border(true)
      if (from === "desktop") {
        setDisableSelect(true);
      }
      return;
    }

    // console.log("pricestepDatatftyfytftyytftf" );
    // console.log("name asdadsada =", name);
    setActiveTab(!activeTab);
    const text = activeTab ? "single" : "pair";
    console.log("text", text);
    setActiveTabName(text);
    setPricestepData({ ...pricestepData, singlePair: text });
  };

  useEffect(() => {
    // console.log("dfff>>", pricestepData, checkAssets);
    // setPricestepData({
    //   ...pricestepData,
    //   singlePair: pricestepData?.singlePair
    //     ? pricestepData?.singlePair
    //     : checkAssets
    //     ? "single"
    //     : "",
    // });
    if (pricestepData?.singlePair === "pair") {
      handleTab("pair");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tab-btns-wrapper">
      {/* <span
        className={`tab-btn ${activeTab === "single" ? "active" : ""}`}
        onClick={() => handleTab(name, "single")}
        role="presentation"
      >
        single
      </span>
      <span
        className={`tab-btn ${activeTab === "pair" ? "active" : ""}`}
        onClick={() => handleTab(name, "pair")}
        role="presentation"
      >
        pair
      </span> */}
      <input
        className="single-pair-check"
        type="checkbox"
        // disabled={(reportType ==="byGia" )}
        id="checkbox_toggle"
        // onChange={() => handleTab(name)}
        checked={activeTab}
      />
      <div
        className={`single-pair-checkbox ${
          disable_border ? "disable_border" : ""
        } `}
      >
        <label htmlFor="checkbox_toggle" className="single-pair-slide">
          <label
            htmlFor="checkbox_toggle"
            className={`single-pair-toggle  ${
              // (reportType ==="byGia" ) ||
              !pricestepData?.singlePair ? "pair-disabled" : ""
            }`}
          ></label>
          <label
            htmlFor="checkbox_toggle"
            className={`single-pair-text ${
              activeTabName === "single" ? " single" : ""
            }`}
            onClick={() => handleTab("single")}
          >
            single
          </label>
          <label
            htmlFor="checkbox_toggle"
            className={`single-pair-text ${
              activeTabName === "pair" ? "pair" : ""
            }`}
            onClick={() => handleTab("pair")}
          >
            pair
          </label>
        </label>
      </div>
    </div>
  );
};

export default DesktopPairType;
