import { useState } from "react";
import { Button, Navbar } from "react-bootstrap";
import backArrow from "../../assets/images/pricing/back-arrow.svg";
import BackModal from "../backModal/backModal";
import Sidebar from "../sidebar";
import barIcon from "../../assets/images/pricing/menu-icon.svg";
import "./header.css";

const Header = ({title}) => {
  const expand = false;
  const [backModal, setBackModal] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleBackModal = () => {
    setBackModal(!backModal);
  };
  return (
    <div className="header">
      <Navbar key={expand} expand={expand}>
        <div className="header-back">
          <Button onClick={handleBackModal}>
            <img src={backArrow} alt="icon" />
          </Button>
          <p>{title?"Back":"Pricing"}</p>
        </div>
        <Button className="menu-toggle" onClick={handleShow}>
          <img src={barIcon} alt="menu" />
        </Button>
        <Sidebar show={show} handleClose={handleClose} />
      </Navbar>
      <BackModal backModal={backModal} handleBackModal={handleBackModal} />
    </div>
  );
};

export default Header;
