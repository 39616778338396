/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./loader.css";

let count = 0;

const Loader = ({text}) => {
  const [loaderTxt, setLoaderTxt] = useState("Analyzing characteristics");
  const textAr = ["Assessing price per carat", "Here is the price range"];

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 2) {
        setLoaderTxt(textAr[count]);
      } else {
        clearInterval(interval);
      }

      count += 1;
    }, 1500);
    return () => {
      clearInterval(interval);
      count = 0;
    };
  }, []);

  return (
    <div className="loader">
      <div className="spinner"></div>
      <p>{text?text:loaderTxt}</p>
    </div>
  );
};

export default Loader;
