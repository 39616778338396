import { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "react-bootstrap/Button";
import InnerForm from "./innerForm";
import arrow from "../../assets/images/pricing/arrow.svg";
import { PriceIntelligence , PriceIntelligenceWithIdu } from "../../utils/dataPI";
import { PriceContext } from "../../context";
import { priceIntelligenceInstance } from "../../config/axios";
import {
  getPolishAndSymmetryValue,
  calculateThreePercentLess,
  isShapeRound,
} from "../../utils/function";
import { toast } from "react-toastify";
import { diamondDepth , diamondDepthForManual} from "../../utils/diamondData";
import ErrorModal from "../ErrorModal/errorModal";
import UAParser from 'ua-parser-js';

let count = 1;

const PricingSlider = ({ setLoading, setwarningModal }) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData ,  setLoader , checkAssets, reportType , skip  ,setSkip} = getContext;
  const [slides , setSlides] = useState( [ 'weight', 'color', 'saturation', 'shape', 'clarity','fluorescence', 'trueFaceUp', 'polish', 'symmetry', 'ratio', 'colorInnerGrade', 'colorDispersion', 'colorUndertone', 'singlePair' ])
  const navigate = useNavigate();
  const sliderRef = useRef();
  const slider2 = useRef();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slideCount, setSlideCount] = useState(1);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [enableSwipe, setEnableSwipe] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [weightValue, setWeightValue] = useState("");
  const [currentSlideName, setCurrentSlideName] = useState("weight");
  const [errorModal, setErrorModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setNav1(sliderRef.current);
    setNav2(slider2.current);
    return () => {
      count = 1;
    };
  }, []);

  // useEffect(() => {
  //   const handleResize = () => {
  //     navigate(`${window.innerWidth < 1225 ? "/pricing" : "/desktop-pricing"}`, {
  //       state: { action: "fromstartpricing" },
  //     });
  //   };

  //   // Add event listener to detect window resize
  //   window.addEventListener('resize', handleResize);

  //   // Cleanup: Remove event listener when component unmounts
  //   return () => window.removeEventListener('resize', handleResize);
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (checkAssets){
      setPricestepData((prev)=>({
        ...prev ,
        colorInnerGrade: "2",
      colorDispersion: "3",
      colorUndertone: "3",
      }));
      setSlides( [ 'weight','shape', 'color', 'saturation',  'clarity','fluorescence','ratio',  'polish', 'symmetry',  'trueFaceUp', 'defaultIdu','singlePair', 'summary' ])
    }
    if (!checkAssets){
      setSlides( [ 'weight','shape',  'color', 'saturation', 'clarity','fluorescence', 'ratio', 'polish', 'symmetry', 'trueFaceUp', 'colorInnerGrade', 'colorDispersion', 'colorUndertone', 'singlePair' , 'summary' ])
    }
    // eslint-disable-next-line
  }, [checkAssets , count]);

  const handleSubmit = async (payload) => {
    try {
      setLoading(true);
      setLoader(true)
      const { polish, symmetry } = payload || {};

      payload.polishAndSymmetry = getPolishAndSymmetryValue(polish, symmetry);

      const depthItem = (reportType==='byGia'?diamondDepth :diamondDepthForManual).find(
        (item) => item.displayName === payload.trueFaceUp
      );
      if (depthItem) {
        // Update the payload with the Id from the depthItem
        payload.trueFaceUp = Number(depthItem.Id);
      }
      const [ipResponse, uaResult] = await Promise.all([
        fetch("https://api.ipify.org?format=json"),
        new Promise((resolve) => resolve(new UAParser().getResult())),
      ]);
  
      const { ip } = await ipResponse.json();
  
      // Prepare the payload for the API
      const extraInfo = {
        IP: ip,
        "Operating System": uaResult.os.name,
        Browser: uaResult.browser.name,
        Device: uaResult.device.model || "Unknown",
      };
      const response = await priceIntelligenceInstance().post(
        `/price/getreport`,
        { payload ,checkAssets , extraInfo}
      );
     
      setLoader(false)
      setTimeout(() => {
        setLoading(false);
      }, 4500);
      console.log("res", response)
      const { status, msg, price, jobNumber , id , pdfurl } = response?.data || {};
// console.log("stat", status , msg, price, jobNumber)
      if (status === 200) {
        let priceValue = price;
        if (pricestepData?.giasaturation === "Fancy Deep") {
          priceValue = price * 0.94;
        }
        setPricestepData({
          ...pricestepData,
          price: calculateThreePercentLess(priceValue , checkAssets),
          jobNumber,
          pdfurl
        });

        const extraData = {
          ...pricestepData,
          ...payload,
          price: calculateThreePercentLess(priceValue , checkAssets),
          jobNumber,
          pdfurl
        }
        setTimeout(() => {
          navigate("/result" , {state: {id , checkAsset: checkAssets ,
            pricetepData : extraData ,
            reportType: reportType
          }});
        }, 4500);
      } else if (status === 400) {
        setwarningModal(true);
        return;
      } else {
        setPricestepData({ ...pricestepData, price: 0 });
        // toast.error(msg);
        setErrMsg(msg);
        setErrorModal(true);

      }
      console.log("response---", response);
    } catch (error) {
      setPricestepData({ ...pricestepData, price: 0 });
      setTimeout(() => {
        setLoading(false);
      }, 400);

      console.log("error in get price report==>>>>", error);
      console.log("msg", error.response.data.msg);

      if (error.response.data.msg === "Unauthorized Access") {
        setTimeout(() => {
          toast.error(error.response.data.msg);
        }, 500);

        setTimeout(() => {
          navigate("/user-login");
        }, 4500);
      }
    }
  };

  


  // console.log('prie---',sliderRef , slider2 );
  return (
    <div className="pricing-slider">
      <TopSlider
      skip={skip}
      setSkip={setSkip}
        slider2={slider2}
        nav1={nav1}
        setSlideCount={setSlideCount}
        payload={pricestepData}
        sliderIndex={sliderIndex}
        setSliderIndex={setSliderIndex}
        sliderRef={sliderRef}
        enableSwipe={enableSwipe}
        setEnableSwipe={setEnableSwipe}
        setIsEmpty={setIsEmpty}
        weightValue={weightValue}
        checkAssets={checkAssets}
        reportType={reportType}
      />
      <BottomSlider
      skip={skip}
        sliderRef={sliderRef}
        nav2={nav2}
        slideCount={slideCount}
        payload={pricestepData}
        setPayload={setPricestepData}
        setLoading={setLoading}
        handleSubmit={handleSubmit}
        sliderIndex={sliderIndex}
        enableSwipe={enableSwipe}
        isEmpty={isEmpty}
        setIsEmpty={setIsEmpty}
        weightValue={weightValue}
        setWeightValue={setWeightValue}
        checkAssets={checkAssets}
        currentSlideName={currentSlideName}
        setCurrentSlideName={setCurrentSlideName}
        slides={slides}
        setEnableSwipe={setEnableSwipe}

     
      />

<ErrorModal
          errorModal={errorModal}
          setErrorModal={setErrorModal} 
          errMsg={errMsg}
           bg_remove_modal="bg_remove_modal"
         
        />
    </div>
  );
};

const TopSlider = ({
  nav1,
  skip,
  setSkip,
  slider2,
  setSlideCount,
  payload,
  sliderIndex,
  setSliderIndex,
  sliderRef,
  enableSwipe,
  setEnableSwipe,
  setIsEmpty,
  weightValue,
  checkAssets,
  reportType,
}) => {
  let emptySlide = [];
  // const [ratioCount, setRatioCount] = useState(0);
  const slideClassName = document
    ?.getElementsByClassName("top-slider-wrapper")[0]
    ?.getElementsByClassName("slick-slide");

  const dotsClassName = document
    ?.getElementsByClassName("slick-dots")[0]
    ?.getElementsByTagName("li");

  for (let i = 0; i < slideClassName?.length; i++) {
    // slideClassName[i].setAttribute("slide-name", PriceIntelligence[i].name);
    // dotsClassName[i].setAttribute("slide-name", PriceIntelligence[i].name);
    if (slideClassName[i].innerHTML.includes('data-value=""')) {
      slideClassName[i].classList.add("disable-event");
      slideClassName[i].classList.add("empty-field");
      dotsClassName[i].classList.add("disable-event");
      emptySlide.push(slideClassName[i]?.getAttribute("data-index"));
    }
  }

  // const EmptyFields = document?.querySelectorAll(".empty-field");
  // console.log("EmptyFields", EmptyFields);

  // if (EmptyFields?.length > 2) {
  //   setEnableSwipe(false);
  // } else {
  //   setEnableSwipe(true);
  // }
  const settings = {
    className: `top-slider-wrapper ${sliderIndex === 0 ? "first-slide" : ""} ${
      sliderIndex === slideClassName?.length - 1 ? "last-slide" : ""
    }`,
    accessibility: false,
    dots: true,
    arrow: false,
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    swipe: false,
    centerPadding: "120px",
    centerMode: true,
    beforeChange: function (oldIndex, newIndex) {
      setSliderIndex(newIndex);
    },
    afterChange: function (i) {
      setSlideCount(i + 1);
      // console.log("hd2", i , skip)
      // if (skip && i ===8){
      //   sliderRef.current.slickGoTo(i + 1);
      //   // console.log("2>>>", slider2.current)
      //   // console.log("1>>>", sliderRef.current)
      
      //   // slider2.current.slickGoTo(i + 2);

     
      // }else{
       
      // }

    },
  };
// console.log('emptySlide ==>', emptySlide, 'count ==>', count, 'skip ==>', skip, 'checkAssets ==>', checkAssets);

  if ((emptySlide.length > 0 && count ===1) ) {
    // console.log('emptySlide ==>', emptySlide[0], 'count ==>', count);
    setTimeout(() => {
      // console.log('emptySlide ==>', emptySlide[0], 'count ==>', count);
      sliderRef.current.slickGoTo(Number(emptySlide[0]));
      setSlideCount(Number(emptySlide[0]) + 1);
      count++;
    }, 300);
  }
  console.log('emptySlide ==>', emptySlide , payload?.shape);
  // if (!checkAssets && skip && emptySlide.length > 0 && (payload?.shape==="Round" ?  emptySlide[0] === '9' : emptySlide[0] === '8')) {
  //   console.log('emptySlide ==>8', emptySlide[0], 'count ==>', count ,ratioCount  );
  //   if (ratioCount>2) {
  //     setSkip(false);
  //   }
  //   setTimeout(() => {
  //     // console.log('emptySlide ==>', emptySlide[0], 'count ==>', count , );
  //     sliderRef.current.slickGoTo(Number(emptySlide[0]));
  //     setSlideCount(Number(emptySlide[0]) + 1);
  //     count++;
  //     setRatioCount((prev) => prev + 1);
  //     // console.log('ratioCount ==>', ratioCount);
  //   }, 300);
  // }
  // console.log('emptySlide ==>', emptySlide, 'count ==>', count, 'skip ==>', skip, 'checkAssets ==>', checkAssets);
  // if (checkAssets && skip && emptySlide.length > 0 && (payload?.shape==="Round" ?  emptySlide[0] === '10' : emptySlide[0] === '9')) {
  //   console.log('emptySlide9 ==>', emptySlide[0], 'count ==>', count ,ratioCount  );
  //   if (ratioCount>2) {
  //     setSkip(false);
  //   }
  //   setTimeout(() => {
  //     console.log('emptySlide ==>', emptySlide[0], 'count ==>', count , );
  //     sliderRef.current.slickGoTo(Number(emptySlide[0]));
  //     setSlideCount(Number(emptySlide[0]) + 1);
  //     count++;
  //     setRatioCount((prev) => prev + 1);
  //   }, 300);
  // }
  return (
    <div className="top-slider-grid">
      <div>
        <Slider
          {...settings}
          asNavFor={nav1}
          ref={slider2}
          focusOnSelect={true}
        >
          {((checkAssets  ) ? PriceIntelligenceWithIdu : PriceIntelligence).map((data, i) => (
            <div key={i}>
              <div
                className={`top-slide ${!!payload[data.name] ? "visited" : ""}`}
                data-value={
                  ((checkAssets  ) ? PriceIntelligenceWithIdu : PriceIntelligence).length - 1 !== i
                    ? !!payload[data.name]
                      ? payload[data.name]
                      : ""
                    : "summery"
                }
                slide-id={data.name}
              >
                {/* <h3>{data.field}</h3> */}
                {/* <Button>
                  {((checkAssets ) ? PriceIntelligenceWithIdu : PriceIntelligence).length - 1 !== i
                    ? payload[data.name] === true
                      ? "Pair"
                      : payload[data.name] === false
                      ? "Single"
                      : data.name === "weight"
                      ? weightValue
                      : payload[data.name]
                    : "Summery"}
                </Button> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
const BottomSlider = ({
  skip,
  nav2,
  sliderRef,
  slideCount,
  payload,
  handleSubmit,
  sliderIndex,
  enableSwipe,
  isEmpty,
  setIsEmpty,
  weightValue,
  setWeightValue,
  checkAssets,
  currentSlideName, setCurrentSlideName,
  slides,
  setEnableSwipe

}) => {
  const getContext = useContext(PriceContext);
  const {
    setPricestepData,
    pricestepData,
    setLengthValue,
    setWidthValue,
    reportType,
  } = getContext;
  // const [currentSlideName, setCurrentSlideName] = useState("weight");
  const [singleScroll, setSingleScroll] = useState(false);
  const slideClassName = document
    ?.getElementsByClassName("bottom-slider-grid")[0]
    ?.getElementsByClassName("slick-slide");


 

  // for (let i = 0; i < slideClassName?.length; i++) {
  //   slideClassName[i].setAttribute("slide-name", PriceIntelligence[i].name);
  // }
// console.log("payload===>",payload);





useEffect(() => {
  document.addEventListener("touchstart", handleTouch, false);
  document.addEventListener("touchmove", handleTouchMove, false);
  document.addEventListener("touchend", handleTouchEnd, false);

  return () => {
    document.removeEventListener("touchstart", handleTouch, false);
    document.removeEventListener("touchmove", handleTouchMove, false);
    document.removeEventListener("touchend", handleTouchEnd, false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [pricestepData, currentSlideName]);




let initTouchPoint;
var xUp = 0;

function handleTouchEnd(evt) {

  initTouchPoint =evt.changedTouches[0].clientX;
  console.log("initTouchPointEnd>>", initTouchPoint)
  // const ci = slides.indexOf(activeSlide);
//  if (nextMove === "left") {
//   // setActiveSlide(slides[ci - 1])

//   // console.log("payload>>", pricestepData)
// }else{
//   setActiveSlide(slides[ci + 1])

//   // console.log("payload>>", pricestepData)
// }
}

function handleTouch (evt) {
  initTouchPoint =evt.touches[0].clientX;
}



function handleTouchMove(evt) {
  // console.log("currentSlideName", currentSlideName)
   xUp = evt.touches[0].clientX;
   const ci = slides.indexOf(currentSlideName);   
   if (initTouchPoint-xUp > 50) {
    console.log("valueright>>",slides[ci], pricestepData[slides[ci]],"nh",!!pricestepData[slides[ci]] )
    if (slides.length=== 13 && currentSlideName === "defaultIdu") {
      setPricestepData((prevState) => ({ ...prevState, defaultIdu: "Default IDU"}));
    }
   
    if (!pricestepData[slides[ci]]) {
      sliderRef.current.slickGoTo(ci);
    }  
}
}
  const settings = {
    accessibility: false,
    dots: false,
    arrow: false,
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: true,
    centerPadding: "32px",
    centerMode: true,
    // beforeChange: (oldIndex, newIndex) => {
    //   setIsEmpty(false);
    //   console.log("slideCount", slideCount, "newIndex", newIndex , "skiop", skip);
    // },
    // afterChange: function (i) {
    //   if (skip && i === 8) {
    //     sliderRef.current.slickGoTo(i + 1);
    //   }
    // },
    
  };

  const handleNext = () => {

    if (currentSlideName === "colorUndertone"  || (checkAssets && currentSlideName === "ratio")) {
      setPricestepData((prevState) => ({ ...prevState
        , singlePair: "single"
      }));
    }
    if (currentSlideName === "singlePair") {
      setPricestepData((prevState) => ({ ...prevState, defaultIdu: "Default IDU"}));
    }
    if (currentSlideName === "symmetry" && isShapeRound(pricestepData.shape)) {
      setWidthValue(1);
      setLengthValue(1);
      setPricestepData((prevState) => ({
        ...prevState,
        ratio: "1.00",
        length: 1,
        width: 1,
      }));
    }
    sliderRef.current.slickNext();
    setSingleScroll(false);
  };

  useEffect(() => {
    const activeSlideElem = document
      ?.getElementsByClassName("slick-active")[0]
      ?.getElementsByClassName("top-slide")[0]
      ?.getAttribute("slide-id");
    setCurrentSlideName(activeSlideElem);
// eslint-disable-next-line
  }, [sliderIndex]);

  const currentSlideIndex = document
    ?.getElementsByClassName("bottom-slider-grid")[0]
    ?.getElementsByClassName("slick-active")[0]
    ?.getAttribute("data-index");

  useEffect(() => {
    setSingleScroll(true);
    console.log("payload[currentSlideName]",payload[currentSlideName]);
    if (payload[currentSlideName] === "" || !!payload[currentSlideName]) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    if (
      payload[currentSlideName] !== "" &&
      (!!pricestepData?.giaReportNumber ? !isEmpty : isEmpty) && // revise issue
      currentSlideName !== "weight" &&
      // currentSlideName !== "trueFaceUp" &&
      // currentSlideName !== "ratio" &&
      currentSlideName !== "singlePair" &&
      singleScroll
    ) {
      handleNext();
    }

    // console.log("currentSlideName", currentSlideName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload[currentSlideName]]);

  return (
    <div className={`bottom-slider-grid`}>
      <div className="slide-count-grid">
        <div className="slider-dots-wrapper">
          <ul className="slider-dot-ul">
            {/* {((checkAssets  ) ? PriceIntelligenceWithIdu : PriceIntelligence).map((e, i) => (
              <li
                className={`slider-dot ${!!payload[e.name] ? "visited" : ""}
                ${currentSlideName === e.name ? "active" : ""}
              `}
                slide-name={e.name}
                key={i}
              />
            ))} */}
          </ul>
        </div>
        <span className="slide-count">
          {Number(currentSlideIndex) + 1}/{slideClassName?.length}
        </span>
      </div>
      <Slider
        {...settings}
        asNavFor={nav2}
        ref={sliderRef}
        // focusOnSelect={true}
      >
        {((checkAssets  ) ? PriceIntelligenceWithIdu : PriceIntelligence).map((data, i) => (
          <div key={i}>
            <div className={`bottom-slide`}>
              {((checkAssets  ) ? PriceIntelligenceWithIdu : PriceIntelligence).length - 1 === i ? (
                <div className="pricing-inner-layout">
                  <div className="inner-grade summery-card-wrapper">
                    <div className="pricing-grade">
                      <h2>summary [{pricestepData?.singlePair}]</h2>
                      <ul className="summery-content-top">
                        <li>
                          <h6>Weight:</h6>
                          <span>{weightValue}</span>
                        </li>
                        <li>
                          <h6>Saturation:</h6>
                          <span>
                            {pricestepData?.saturation} {pricestepData?.color}
                          </span>
                        </li>
                        <li>
                          <h6>Shape:</h6>
                          <span>{pricestepData?.shape}</span>
                        </li>
                        <li>
                          <h6>Clarity:</h6>
                          <span>{pricestepData?.clarity}</span>
                        </li>
                        <li>
                          <h6>Polish & Symmetry:</h6>
                          <span>
                            {pricestepData?.polish}, {pricestepData?.symmetry}
                          </span>
                        </li>
                        <li>
                          <h6>Fluorescence:</h6>
                          <span>{pricestepData?.fluorescence}</span>
                        </li>

                        <li>
                          <h6>Ratio:</h6>
                          <span>{pricestepData?.ratio}</span>
                        </li>
                      </ul>

                      <ul className="summery-content-top summery-content-bottom">
                      <li>
                          <h6>True Face-Up:</h6>
                          <span>{pricestepData?.trueFaceUp}</span>
                        </li>
                        <li>
                          <h6>Inner Grade:</h6>
                          <span>{pricestepData?.colorInnerGrade}</span>
                        </li>
                        <li>
                          <h6>dispersion:</h6>
                          <span>{pricestepData?.colorDispersion}</span>
                        </li>
                        <li>
                          <h6>undertone:</h6>
                          <span>{pricestepData?.colorUndertone}</span>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (     
                <InnerForm
                  heading={data.field}
                  infoTxt={data.info}
                  name={data.name}
                  index={i}
                  weightValue={weightValue}
                  setWeightValue={setWeightValue}
                  payload={payload}
                  checkAssets={checkAssets}
                />
              )}
            </div>
          </div>
        ))}
      </Slider>
      {console.log("currentSlideName>>>", (reportType==='byGia'  && checkAssets) ? false :  payload[currentSlideName] === "")}

      <div className="next-btn-grid next-price-btn">
        {/* {console.log("reportType>>>>>>>>>>>>>>", reportType)} */}
        { reportType==="byGia" && pricestepData && (
          <p className="botton-diamond-data">
            {pricestepData?.carat_weight}ct, {pricestepData?.saturation}{" "}
            {pricestepData?.color}, {pricestepData?.shape}{" "}
            {pricestepData?.clarity}
          </p>
        )}
        {currentSlideName !== "summary" ? (
          <Button
            className={`next-btn ${
              (!!payload[currentSlideName] || (reportType==='byGia'  && checkAssets) || currentSlideName==="defaultIdu" ) ? "" : "disabled"
            }`}
            onClick={() => handleNext()}
            disabled={ (reportType==='byGia'  && checkAssets) ? false :  payload[currentSlideName] === ""   }
          >
            Next<img src={arrow} alt="arrow" />
          </Button>
        ) : (
          <Button className={`next-btn`} onClick={() => handleSubmit(payload)}>
            Calculate price
          </Button>
        )}
      </div>
    </div>
  );
};

export default PricingSlider;
