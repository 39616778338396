import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { diamondColorDispersion } from "../../utils/diamondData";
import { PriceContext } from "../../context";

const DispersionForm = ({ name }) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData,reportType } = getContext;

  const [show, setShow] = useState(false);
  const [defaultDispersion, setDefaultDispersion] = useState("Select");
  useEffect(() => {
    setDefaultDispersion(pricestepData[name] ? pricestepData[name] : "Select");
    // eslint-disable-next-line
}, [pricestepData?.shape ]);
  const handleModal = () => {
    setShow(!show);
  };
  const handleSelect = (value, name) => {
    setDefaultDispersion(value);
    setPricestepData({ ...pricestepData, [name]: value });
    setShow(false);
  };
  return (
    <>
      <div className="form-wrapper">
        <div
          className={`${reportType ==="byGia"? "disable_input1":""} select-section`}
          onClick={handleModal}
          role="presentation"
          style={{
            opacity: `${!!pricestepData[name] ? "1" : "0.5"}`,
          }}
        >
          <h6>{defaultDispersion}</h6>
        </div>
      </div>
      <DispersionSelector
        show={show}
        handleModal={handleModal}
        handleSelect={handleSelect}
        name={name}
        pricestepData={pricestepData}
      />
    </>
  );
};

export default DispersionForm;

const DispersionSelector = ({ show, handleModal, handleSelect, name , pricestepData }) => {
  return (
    <Modal
      className="pi-input-selector"
      show={show}
      onHide={handleModal}
      backdropClassName="backdrop-input-bg"
      centered
    >
      <Modal.Body>
        <div className="input-option-wrapper">
          {diamondColorDispersion.map((el, i) => (
            <div
              className={`pi-option-elem ${
              el === "4+" && pricestepData?.shape === "Round"
                ? "step-option-disable"
                : ""
            }`}
              onClick={() => {
                if (el === "4+" && pricestepData?.shape === "Round") {
                  return;
                }
                handleSelect(el, name);
              }}
              key={i}
            >
              Dispersion {el}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
