import React, { useState } from "react";
import { toast } from "react-toastify";
import {  useNavigate } from "react-router-dom";
import PriceIntelligenceLayout from "..";
import Header from "../header";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import arrow from "../../assets/images/pricing/arrow.svg";
import { priceIntelligenceInstance } from "../../config/axios";
import VisibleIcon from "../../assets/icon/visibleIcon";
import NotVisibleIcon from "../../assets/icon/notVisibleIcon";
// import Modaldesc from "../Modal/Modaldesc";
import ErrorModal from "../ErrorModal/errorModal";


const UserLogin = () => {
  const navigate = useNavigate();
  // const [modalStatus, setModalStatus] = useState(false);
  // const [modaldescreption, setModalDescreption] = useState("");
  const initialState = {
    email: "",
    password: "",
  };
  const [payload, setPayload] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [errorModal, setErrorModal] = useState(false);

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    if (name === "email") {
      value = value.toLowerCase();
    }
    setPayload({ ...payload, [name]: value });
  };

  const handleSubmit = async (e) => {
    try {
      const response = await priceIntelligenceInstance().post(
        "/users/v2/login",
        payload
      );




      const { status, msg, token, refreshToken,userDescclaimer,user ,mag } = response.data || {};
      if (status === 200) {
        toast.success(msg, { toastId: "loginsuccess" });
        localStorage.setItem("price#@user", token);
        localStorage.setItem("price@refreshtoken", refreshToken);
        localStorage.setItem("price#@useremail", user);
        if(!userDescclaimer){
          navigate("/disclaimer");
          return;
        }
        else{
          navigate("/start-pricing")
          return;
        }


      }
     else if(status===401){
      setErrMsg(msg)
      setErrorModal(true)
        
        // setModalDescreption(msg)
        // setModalStatus(true)
        return;
      }else if (status === 402) {
        setErrMsg(mag);
        setErrorModal(true);
      //  toast.error(mag, { toastId: "loginerror" });
        return;
      }
      setErrorModal(true)
      setErrMsg(msg)
      // toast.error(msg, { toastId: "loginerror" });
    } catch (e) {
      console.log("login error =>", e);
    }
  };

  return (
    <PriceIntelligenceLayout>
      <div className="pricing-userlogin">
        <Header />
        <div className="pricing-userlogin-content">
          <div className="pricing-userlogin-content-form">
            <h1>Enter Email & password</h1>
            <div className="pricing-userlogin-content-input">
              <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  name="email"
                  onChange={(e) => handleOnChange(e)}
                />
                <Form.Text className="d-none">
                  Please enter valid email
                </Form.Text>
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingPassword"
                label="Password"
                className="error_msg1"
              >
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  onChange={(e) => handleOnChange(e)}
                  autoComplete="false"
                />
                <span
                  className="visible-wrapper"
                  onClick={() => setShowPassword(!showPassword)}
                  role="presentation"
                >
                  {showPassword ? <NotVisibleIcon /> : <VisibleIcon />}
                </span>
                <Form.Text className="d-none">
                  the password should contain 8 letters
                </Form.Text>
              </FloatingLabel>
            </div>
            {/* <Link  to="">Forgot email or password?</Link> */}
          </div>
          <div className="pricing-userlogin-content-btn">
            <Button onClick={() => handleSubmit()}>
              <span>Next</span>
              <span>
                <img src={arrow} alt="arrow" />
              </span>
            </Button>
            {/* <Button disabled={active} onClick={()=>handleSubmit()}>
              <span>Next</span>
              <span>
                <img src={arrow} alt="arrow" />
              </span>
            </Button> */}
          </div>
        </div>
      </div>


        <ErrorModal
        errorModal={errorModal}
        setErrorModal={setErrorModal}
        errMsg={errMsg}
         bg_remove_modal="bg_remove_modal"

      />
      {/* <Modaldesc
      modalStatus={modalStatus}
      setModalStatus={setModalStatus}
      disclamers={modaldescreption}
      heading={"Login Alert"}
      /> */}
    </PriceIntelligenceLayout>
  );
};

export default UserLogin;
