/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import Button from "react-bootstrap/Button";
import PricingLayout from "./pricingLayout";
import infoIcon from "../../assets/images/pricing/info-icon.svg";
import InfoModal from "../infoModal/infoModal";
import WeightForm from "./weightForm";
import ColorForm from "./colorForm";
import SaturationForm from "./saturationForm";
import ShapeForm from "./shapeForm";
import ClarityForm from "./clarityForm";
import FluorescenceForm from "./fluorescenceForm";
import DepthForm from "./depthForm";
import PolishForm from "./polishForm";
import SymmetryForm from "./symmetryForm";
import RatioForm from "./ratioForm";
import InnerGradeForm from "./innerGradeForm";
import DispersionForm from "./dispersionForm";
import UndertoneForm from "./undertoneForm";
import PairType from "./pairType";
import "./pricing.css";
import DefaultIdu from "./defaultIdu";
// import { useContext } from "react";
// import { PriceContext } from "../../context";

const InnerForm = ({ heading, infoTxt, name, weightValue, setWeightValue,payload}) => {
  const [infoModal, setInfoModal] = useState(false);
  // const getContext = useContext(PriceContext);
  // const { setPricestepData, pricestepData, reportType ,  setSkip } = getContext;
  // const handleSkip = () => {
    
  // setSkip(true);
  //   setPricestepData({
  //     ...pricestepData,
  //     polish: "Vg",
  //     symmetry: "G",
  //   });
  // };
  return (
    <>
      <PricingLayout name={name}>
        <div className={`inner-grade`}>
          <div className="pricing-grade">
          {heading==="IDU"?"":
            <Button onClick={() => setInfoModal(true)}>
              <img src={infoIcon} alt="" />
            </Button>}
            <h2 className={`${heading==="IDU"?"text-white":""} ${heading==="Ratio" ? "ratio-pd":""}`}>{heading ==="IDU"?"Default Visual Assets":heading}</h2>
            <div className={`${name === "defaultIdu"?"defaultIdu_pd":""} select-grid`}>
              {name === "weight" && (
                <WeightForm
                  name={name}
                  weightValue={weightValue}
                  setWeightValue={setWeightValue}
                />
              )}
              {name === "color" && <ColorForm name={name} />}
              {name === "saturation" && <SaturationForm name={name} />}
              {name === "shape" && <ShapeForm name={name} />}
              {name === "clarity" && <ClarityForm name={name} />}
              {name === "fluorescence" && <FluorescenceForm name={name} />}
              {name === "trueFaceUp" && <DepthForm name={name} />}
              {name === "polish" && <PolishForm name={name} />}
              {name === "symmetry" && <SymmetryForm name={name} />}
              {name === "ratio" && <RatioForm name={name} payload={payload} />}
              {name === "colorInnerGrade" && <InnerGradeForm name={name} />}
              {name === "colorDispersion" && <DispersionForm name={name} />}
              {name === "colorUndertone" && <UndertoneForm name={name} />}
              {name === "singlePair" && <PairType name={name} />}
              {name === "defaultIdu" && <DefaultIdu payload={payload}/>}
            </div>
            {/* {reportType !== "byGia" && name === "polish" && (
              <p className="polish-skip-text" onClick={handleSkip}>
                Skip
              </p>
            )} */}
          </div>
        </div>
        <InfoModal
          infoModal={infoModal}
          setInfoModal={setInfoModal}
          heading={heading}
          infoTxt={infoTxt}
        />
      </PricingLayout>
    </>
  );
};

export default InnerForm;
