import React, { createContext, useState } from 'react';

export const PriceContext = createContext();

const PriceDataProvider = (props) => {
  const { children } = props;

  const initPayload = {
    weight: "",
    color: "",
    saturation: "",
    shape: "",
    clarity: "",
    fluorescence: "",
    trueFaceUp: "",
    polish: "",
    symmetry: "",
    ratio: "",
    colorInnerGrade: "",
    colorDispersion: "",
    colorUndertone: "",
    singlePair: "",
    carat_weight:"",
    // depth_pct:"",
    table_pct:"",
    matchingPairs:"",
    price: 0,
    defaultIdu:""
  };
  const initialuserDetails = {
    email:"",
    lastName:"",
    firstName:"",
    phone:"",
    password:"",
    JoinDate:"",
  };

  const [pricestepData, setPricestepData] = useState(initPayload);
  const [giaReport, setGiaReport] = useState({});
  const [lengthValue, setLengthValue] = useState('');
  const [widthValue, setWidthValue] = useState('');
  const [skip, setSkip] = useState(false);
  // Check manual/auto pricing
  const [isManualPricing, setIsManualPricing] = useState(true);
  const [PreviousSearchesData, setPreviousSearchesData] = useState([]);
  const [userDetails,setUserDetails]=useState(initialuserDetails)
  const [piCredits, setPICredits] = useState(null)
  const [loader, setLoader] = useState(false);
  const [reportType, setReportType] = useState("byGia")
  const [inputChecked,setInputChecked]=useState(false)
  const [checkAssets, setCheckAssets] = useState(localStorage.getItem('checkAssets') ? localStorage.getItem('checkAssets')==='true' ? true: false : false);

    
  return (
    <PriceContext.Provider value={{
        pricestepData, setPricestepData,giaReport, setGiaReport,initPayload,isManualPricing, setIsManualPricing,lengthValue, setLengthValue,widthValue, setWidthValue, PreviousSearchesData, setPreviousSearchesData, userDetails,setUserDetails, reportType,setReportType , piCredits, setPICredits,loader, setLoader,inputChecked,setInputChecked , checkAssets, setCheckAssets , skip, setSkip
    }}>
      {children}
    </PriceContext.Provider>
  );
};

export default PriceDataProvider;
