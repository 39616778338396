import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { diamondShapes } from "../../utils/diamondData";
import { PriceContext } from "../../context";

const ShapeForm = ({ name }) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData, reportType } = getContext;
  const { shape } = pricestepData || {};
  const [show, setShow] = useState(false);
  const [defaultShape, setDefaultShape] = useState(shape ? shape : "Select");
  const handleModal = () => {
    if (reportType === "byGia") {
      return;
    }
    setShow(!show);
  };
  const handleSelect = (value, name) => {
    setDefaultShape(value);
    if (name === "shape" && value === "Round") {
      setPricestepData({ ...pricestepData, [name]:value , 
       ratio: 1,
       colorDispersion:  pricestepData?.colorDispersion==="4+" ? "": pricestepData?.colorDispersion,
       trueFaceUp:  pricestepData?.trueFaceUp==="Face-Up - Large" ? "": pricestepData?.trueFaceUp,
      
      });
    }else{
      setPricestepData({ ...pricestepData, [name]: value, 
        ratio: ""

       });
    }
    setShow(false);
  };

  useEffect(() => {
    setDefaultShape(shape ? shape : "Select");
  }, [shape]);


  return (
    <>
      <div className="form-wrapper">
        <div
           className={`${reportType ==="byGia"? "disable_input":""} select-section`}
          onClick={handleModal}
          role="presentation"
          style={{
            opacity: `${pricestepData[name] === "" ? "0.5" : "1"}`,
          }}
        >
          <h6>{defaultShape}</h6>
        </div>
      </div>
      <ShapeSelector
        show={show}
        handleModal={handleModal}
        handleSelect={handleSelect}
        name={name}
      />
    </>
  );
};

export default ShapeForm;

const ShapeSelector = ({ show, handleModal, handleSelect, name }) => {
  return (
    <Modal
      className="pi-input-selector"
      show={show}
      onHide={handleModal}
      backdropClassName="backdrop-input-bg"
      centered
    >
      <Modal.Body>
        <div className="input-option-wrapper">
          {diamondShapes.map((el, i) => (
            <div
              className="pi-option-elem"
              onClick={() => handleSelect(el, name)}
              key={i}
            >
              {el}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
