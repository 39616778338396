import React, { useContext, useEffect, useState } from "react";
import DesktopPricing from "./desktopPricing";
import Pricing from "./pricing";
import { useLocation } from "react-router-dom";
import { PriceContext } from "../../context";
// import { diamondDesktopPolish } from "../../utils/diamondData";

const IndexPricing = () => {
  const getContext = useContext(PriceContext);
  const {  pricestepData } = getContext;
  const [enableMobilePricing, setEnableMobilePricing] = useState(
    window.innerWidth < 1225 ? true : false
  );
  const location = useLocation();
  const { action, priceData } = location.state ?? {};
  // console.log("no action");
  if (!action && !priceData) {
    window.location.href = "/start-pricing";
  }

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
      setEnableMobilePricing(window.innerWidth < 1225 ? true : false);
      if (window.innerWidth < 1225) {
        // console.log("mobile pricing",pricestepData?.polish , diamondDesktopPolish.find(x=>x.displayName===pricestepData?.polish)?.code)
        // priceData.polish=diamondDesktopPolish.find(x=>x.displayName===pricestepData?.polish)?.code
        
        // setPricestepData({
        //   ...pricestepData,
        //   polish: polish
        // })
        // setPricestepData({
        //   ...pricestepData,
        //   polish: diamondDesktopPolish.find(x=>x.displayName===pricestepData?.polish)?.code
        // })
        
      }else{

      }
      //   navigate(`${window.innerWidth < 1225 ? "/pricing" : "/desktop-pricing"}`, {
      //     state: { action },
      //   });
    };

    // Add event listener to detect window resize
    window.addEventListener("resize", handleResize);

    // Cleanup: Remove event listener when component unmounts
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, [pricestepData?.polish]);

  return (
    <>
      {enableMobilePricing ? (
        <Pricing action={action} priceData={priceData} />
      ) : (
        <DesktopPricing action={action} priceData={priceData} />
      )}
    </>
  );
};

export default IndexPricing;
